 

import React, { useState, useEffect, useCallback, useMemo } from "react";

const SupplyChain_Permission = ({ onPermissionsChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const permissionHierarchy = useMemo(
    () => ({
      15: { children: [240, 241, 242, 243, 244, 245] },
      240: { children: [1790, 1800, 1810] },
      241: { children: [1820, 1830] },
      242: { children: [1840, 1850] },
      243: { children: [1860, 1870, 1880, 1890, 1900] },
      244: { children: [1910, 1920, 1930, 1940] },
      245: { children: [1950, 1951, 1952, 1953, 1954, 1955] },

      1800: { children: [2600, 2601] },
      1810: { children: [2610, 2611] },
      1840: { children: [2620, 2621, 2622] },
      1850: { children: [2630, 2631, 2632] },
      1860: { children: [2640, 2641, 2642, 2643] },
      1870: { children: [2650, 2651, 2652, 2653, 2654, 2655, 2656] },
      1880: { children: [2660, 2661, 2662, 2663] },
      1890: { children: [2670, 2671, 2672] },
      1900: { children: [2680, 2681] },
      1910: { children: [2700, 2701] },
      1920: { children: [2710, 2711] },
      1930: { children: [2720] },
      1940: { children: [2730, 2731] },

      2650: { children: [10030, 10031] },
      2651: { children: [10040, 10041] },
      2661: { children: [10050, 10051] },
      2671: { children: [10060, 10061] },
      2672: { children: [10070, 10071] },
      // Add the rest of your permissions here
    }),
    []
  );

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    // Ensure HRIS permission ID (37) is included if any HRIS permission is selected
    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[15].children.includes(perm)
      ) &&
      !selectedPermissions.includes(15)
    ) {
      selectedPermissions.push(15);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange, permissionHierarchy]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  return (
    <div>
      <div>
        <div className="">
          <p className="font-bold text-[20px] ml-[20%] mt-4 underline">
            Supply Chain
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Dashboard
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="100"
                      checked={!!checkedValues[100]}
                      onChange={() => handleCheckboxChange(100)}
                    />
                    View Recommendations
                  </label>
                  <div className="ml-[9%]">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="101"
                          checked={!!checkedValues[101]}
                          onChange={() => handleCheckboxChange(101)}
                        />
                        View the Recommendation of Suppliers
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="102"
                      checked={!!checkedValues[102]}
                      onChange={() => handleCheckboxChange(102)}
                    />
                    Send Emails
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-col gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="103"
                          checked={!!checkedValues[103]}
                          onChange={() => handleCheckboxChange(103)}
                        />
                        View Email
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="104"
                          checked={!!checkedValues[104]}
                          onChange={() => handleCheckboxChange(104, 103)}
                        />
                        Send
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="104"
                          checked={!!checkedValues[104]}
                          onChange={() => handleCheckboxChange(104, 103)}
                        />
                        Trash
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="104"
                          checked={!!checkedValues[104]}
                          onChange={() => handleCheckboxChange(104, 103)}
                        />
                        Compose
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="105"
                      checked={!!checkedValues[105]}
                      onChange={() => handleCheckboxChange(105)}
                    />
                    Acceptance Letter
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-col gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="103"
                          checked={!!checkedValues[103]}
                          onChange={() => handleCheckboxChange(103)}
                        />
                        View Letters
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="104"
                          checked={!!checkedValues[104]}
                          onChange={() => handleCheckboxChange(104, 103)}
                        />
                        Add Deadline
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="104"
                          checked={!!checkedValues[104]}
                          onChange={() => handleCheckboxChange(104, 103)}
                        />
                        Upload Doc
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="104"
                          checked={!!checkedValues[104]}
                          onChange={() => handleCheckboxChange(104, 103)}
                        />
                        Submit
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="105"
                      checked={!!checkedValues[105]}
                      onChange={() => handleCheckboxChange(105)}
                    />
                    Create Tenders
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-col gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="103"
                          checked={!!checkedValues[103]}
                          onChange={() => handleCheckboxChange(103)}
                        />
                        Create Tender
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="104"
                          checked={!!checkedValues[104]}
                          onChange={() => handleCheckboxChange(104, 103)}
                        />
                        Add
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="104"
                          checked={!!checkedValues[104]}
                          onChange={() => handleCheckboxChange(104, 103)}
                        />
                        Upload Doc
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="104"
                          checked={!!checkedValues[104]}
                          onChange={() => handleCheckboxChange(104, 103)}
                        />
                        Submit
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="105"
                      checked={!!checkedValues[105]}
                      onChange={() => handleCheckboxChange(105)}
                    />
                    View Tenders
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-col gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="104"
                          checked={!!checkedValues[104]}
                          onChange={() => handleCheckboxChange(104, 103)}
                        />
                        Edit
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Purchase Requests
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1790"
                      checked={!!checkedValues[1790]}
                      onChange={() => handleCheckboxChange(1790)}
                    />
                    Create Purchase Requests
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1800"
                      checked={!!checkedValues[1800]}
                      onChange={() => handleCheckboxChange(1800)}
                    />
                    Purchase Requests
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2600"
                          checked={!!checkedValues[2600]}
                          onChange={() => handleCheckboxChange(2600, 1800)}
                        />
                        View
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2601"
                          checked={!!checkedValues[2601]}
                          onChange={() => handleCheckboxChange(2601, 1800)}
                        />
                        Send For Chairman Approval
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1810"
                      checked={!!checkedValues[1810]}
                      onChange={() => handleCheckboxChange(1810)}
                    />
                    Request Approved By Chairman
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2610"
                          checked={!!checkedValues[2610]}
                          onChange={() => handleCheckboxChange(2610, 1810)}
                        />
                        View
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2611"
                          checked={!!checkedValues[2611]}
                          onChange={() => handleCheckboxChange(2611, 1810)}
                        />
                        Send For Chairman Approval
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Decisions
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1820"
                      checked={!!checkedValues[1820]}
                      onChange={() => handleCheckboxChange(1820)}
                    />
                    View Decisions
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1830"
                      checked={!!checkedValues[1830]}
                      onChange={() => handleCheckboxChange(1830)}
                    />
                    View Activity Log
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Suppliers
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1840"
                      checked={!!checkedValues[1840]}
                      onChange={() => handleCheckboxChange(1840)}
                    />
                    Supplier Category
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2620"
                          checked={!!checkedValues[2620]}
                          onChange={() => handleCheckboxChange(2620, 1840)}
                        />
                        Add
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2621"
                          checked={!!checkedValues[2621]}
                          onChange={() => handleCheckboxChange(2621, 1840)}
                        />
                        Edit
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2622"
                          checked={!!checkedValues[2622]}
                          onChange={() => handleCheckboxChange(2622, 1840)}
                        />
                        Delete
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1850"
                      checked={!!checkedValues[1850]}
                      onChange={() => handleCheckboxChange(1850)}
                    />
                    Supplier Details
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2630"
                          checked={!!checkedValues[2630]}
                          onChange={() => handleCheckboxChange(2630, 1850)}
                        />
                        Add
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2631"
                          checked={!!checkedValues[2631]}
                          onChange={() => handleCheckboxChange(2631, 1850)}
                        />
                        Edit
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2632"
                          checked={!!checkedValues[2632]}
                          onChange={() => handleCheckboxChange(2632, 1850)}
                        />
                        Delete
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Commitees
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1860"
                      checked={!!checkedValues[1860]}
                      onChange={() => handleCheckboxChange(1860)}
                    />
                    Appoint Committee Member
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2640"
                          checked={!!checkedValues[2640]}
                          onChange={() => handleCheckboxChange(2640, 1860)}
                        />
                        Update
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2641"
                          checked={!!checkedValues[2641]}
                          onChange={() => handleCheckboxChange(2641, 1860)}
                        />
                        Remove
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2642"
                          checked={!!checkedValues[2642]}
                          onChange={() => handleCheckboxChange(2642, 1860)}
                        />
                        Add
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2643"
                          checked={!!checkedValues[2643]}
                          onChange={() => handleCheckboxChange(2643, 1860)}
                        />
                        Submit
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1870"
                      checked={!!checkedValues[1870]}
                      onChange={() => handleCheckboxChange(1870)}
                    />
                    Technical Evalution Committee
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-5 mt-2">
                      <label className="block mb-2 text-blue-500">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2650"
                          checked={!!checkedValues[2650]}
                          onChange={() => handleCheckboxChange(2650)}
                        />
                        Bid Evaluation Summary Report
                      </label>
                      <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10030"
                              checked={!!checkedValues[10030]}
                              onChange={() =>
                                handleCheckboxChange(10030, 2650, 1870)
                              }
                            />
                            View
                          </label>
                        </div>
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10031"
                              checked={!!checkedValues[10031]}
                              onChange={() =>
                                handleCheckboxChange(10031, 2650, 1870)
                              }
                            />
                            Create
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="ml-5 mt-2">
                      <label className="block mb-2 text-blue-500">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2651"
                          checked={!!checkedValues[2651]}
                          onChange={() => handleCheckboxChange(2651, 1870)}
                        />
                        View Committee Members Details
                      </label>
                    </div>
                    <div className="ml-5 mt-2">
                      <label className="block mb-2 text-blue-500">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2652"
                          checked={!!checkedValues[2652]}
                          onChange={() => handleCheckboxChange(2652, 1870)}
                        />
                        Meeting Reports
                      </label>
                      <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10040"
                              checked={!!checkedValues[10040]}
                              onChange={() =>
                                handleCheckboxChange(10040, 2652, 1870)
                              }
                            />
                            View
                          </label>
                        </div>
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10041"
                              checked={!!checkedValues[10041]}
                              onChange={() =>
                                handleCheckboxChange(10041, 2652, 1870)
                              }
                            />
                            Create
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="ml-5 mt-2">
                      <label className="block mb-2 text-blue-500">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2653"
                          checked={!!checkedValues[2653]}
                          onChange={() => handleCheckboxChange(2653, 1870)}
                        />
                        View Bidder Details
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2654"
                          checked={!!checkedValues[2654]}
                          onChange={() => handleCheckboxChange(2654, 1870)}
                        />
                        Add Evaluation Criteria
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2655"
                          checked={!!checkedValues[2655]}
                          onChange={() => handleCheckboxChange(2655, 1870)}
                        />
                        Export As csv
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2656"
                          checked={!!checkedValues[2656]}
                          onChange={() => handleCheckboxChange(2656, 1870)}
                        />
                        Save Changes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1880"
                      checked={!!checkedValues[1880]}
                      onChange={() => handleCheckboxChange(1880)}
                    />
                    Sample Testing Committee
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-5 mt-2">
                      <label className="block mb-2 text-blue-500">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2661"
                          checked={!!checkedValues[2661]}
                          onChange={() => handleCheckboxChange(2661, 1880)}
                        />
                        Sample Report Document
                      </label>
                      <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10050"
                              checked={!!checkedValues[10050]}
                              onChange={() =>
                                handleCheckboxChange(10050, 2661, 1880)
                              }
                            />
                            View
                          </label>
                        </div>
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10051"
                              checked={!!checkedValues[10051]}
                              onChange={() =>
                                handleCheckboxChange(10051, 2661, 1880)
                              }
                            />
                            Create
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="ml-5 mt-2">
                      <label className="block mb-2 text-blue-500">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2660"
                          checked={!!checkedValues[2660]}
                          onChange={() => handleCheckboxChange(2660, 1880)}
                        />
                        Sample Reports
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2662"
                          checked={!!checkedValues[2662]}
                          onChange={() => handleCheckboxChange(2662, 1880)}
                        />
                        Add Testing Committee
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2663"
                          checked={!!checkedValues[2663]}
                          onChange={() => handleCheckboxChange(2663, 1880)}
                        />
                        Save and Send
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1890"
                      checked={!!checkedValues[1890]}
                      onChange={() => handleCheckboxChange(1890)}
                    />
                    Tender Opening Committee
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-5 mt-2">
                      <label className="block mb-2 text-blue-500">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2671"
                          checked={!!checkedValues[2671]}
                          onChange={() => handleCheckboxChange(2671, 1890)}
                        />
                        Bidder Documents
                      </label>
                      <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10060"
                              checked={!!checkedValues[10060]}
                              onChange={() =>
                                handleCheckboxChange(10060, 2671, 1890)
                              }
                            />
                            View
                          </label>
                        </div>
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10061"
                              checked={!!checkedValues[10061]}
                              onChange={() =>
                                handleCheckboxChange(10061, 2671, 1890)
                              }
                            />
                            Create
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="ml-5 mt-2">
                      <label className="block mb-2 text-blue-500">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2672"
                          checked={!!checkedValues[2672]}
                          onChange={() => handleCheckboxChange(2672, 1890)}
                        />
                        Opening Price Form
                      </label>
                      <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10070"
                              checked={!!checkedValues[10070]}
                              onChange={() =>
                                handleCheckboxChange(10070, 2672, 1890)
                              }
                            />
                            View
                          </label>
                        </div>
                        <div className="ml-[9%] text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="10071"
                              checked={!!checkedValues[10071]}
                              onChange={() =>
                                handleCheckboxChange(10071, 2672, 1890)
                              }
                            />
                            Create
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2670"
                          checked={!!checkedValues[2670]}
                          onChange={() => handleCheckboxChange(2670, 1890)}
                        />
                        Send mail
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1900"
                      checked={!!checkedValues[1900]}
                      onChange={() => handleCheckboxChange(1900)}
                    />
                    Sent for Approvals
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-5 mt-2">
                      <label className="block mb-2 text-blue-500">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2680"
                          checked={!!checkedValues[2680]}
                          onChange={() => handleCheckboxChange(2680, 1900)}
                        />
                        Approve
                      </label>
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2681"
                          checked={!!checkedValues[2681]}
                          onChange={() => handleCheckboxChange(2681, 1900)}
                        />
                        Reject
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Purchase Order
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1910"
                      checked={!!checkedValues[1910]}
                      onChange={() => handleCheckboxChange(1910)}
                    />
                    Purchase Order
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2700"
                          checked={!!checkedValues[2700]}
                          onChange={() => handleCheckboxChange(2700, 1910)}
                        />
                        View
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2701"
                          checked={!!checkedValues[2701]}
                          onChange={() => handleCheckboxChange(2701, 1910)}
                        />
                        Open
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1920"
                      checked={!!checkedValues[1920]}
                      onChange={() => handleCheckboxChange(1920)}
                    />
                    Invoice
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2710"
                          checked={!!checkedValues[2710]}
                          onChange={() => handleCheckboxChange(2710, 1920)}
                        />
                        View
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2711"
                          checked={!!checkedValues[2711]}
                          onChange={() => handleCheckboxChange(2711, 1920)}
                        />
                        Upload
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1930"
                      checked={!!checkedValues[1930]}
                      onChange={() => handleCheckboxChange(1930)}
                    />
                    Received GRN
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2720"
                          checked={!!checkedValues[2720]}
                          onChange={() => handleCheckboxChange(2720, 1930)}
                        />
                        View
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1940"
                      checked={!!checkedValues[1940]}
                      onChange={() => handleCheckboxChange(1940)}
                    />
                    Voucher
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2730"
                          checked={!!checkedValues[2730]}
                          onChange={() => handleCheckboxChange(2730, 1940)}
                        />
                        View
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2731"
                          checked={!!checkedValues[2731]}
                          onChange={() => handleCheckboxChange(2731, 1940)}
                        />
                        Create
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Purchase Dashboard
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1950"
                      checked={!!checkedValues[1950]}
                      onChange={() => handleCheckboxChange(1950)}
                    />
                    Suppliers
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1951"
                      checked={!!checkedValues[1951]}
                      onChange={() => handleCheckboxChange(1951)}
                    />
                    Purchase Requests
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1952"
                      checked={!!checkedValues[1952]}
                      onChange={() => handleCheckboxChange(1952)}
                    />
                    View Recommendation
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1953"
                      checked={!!checkedValues[1953]}
                      onChange={() => handleCheckboxChange(1953)}
                    />
                    Send a Mail
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1954"
                      checked={!!checkedValues[1954]}
                      onChange={() => handleCheckboxChange(1954)}
                    />
                    Acceptance letter
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1955"
                      checked={!!checkedValues[1955]}
                      onChange={() => handleCheckboxChange(1955)}
                    />
                    Create a tender
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SupplyChain_Permission;
