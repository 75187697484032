/** @format */

import React from "react";
import { Link } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";

function Attendance() {
  const { hasPermission } = usePermissions();

  const attendance = [
    {
      id: 1,
      header: "In-Time & ",
      header2: "Out-Time",
      headerClasses: "text-black  ",
      linkTo: "/in-and-out-regular",
      permissionId: 1010,
    },
    {
      id: 2,
      header: "Permission Grant",
      header2: " (Lateness)",
      headerClasses: "text-black",
      linkTo: "/lateness-permission",
      permissionId: 1012,
    },
    {
      id: 3,
      header: "Over Time",
      header2: "(OT)",
      headerClasses: "text-black",
      linkTo: "/over-time",
      permissionId: 1013,
    },
    {
      id: 4,
      header: "Timetable",
      header2: "Management",
      headerClasses: "text-black",
      linkTo: "/Timetable-management",
      permissionId: 1014,
    },
    {
      id: 4,
      header: "Attendance",
      header2: "Report",
      headerClasses: "text-black",
      linkTo: "/attendance-report",
      permissionId: 1014,
    },
  ];
  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-header ml-6 mt-6 font-bold text-[#797C80] ">
        Time & Attendance Management
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[2.5%] mx-6 font-sans ">
        {attendance.map((card) =>
          hasPermission(card.permissionId) ? (
            <Link key={card.id} to={card.linkTo}>
              <div
                key={card.id}
                className="bg-white rounded-[32px] h-[200px] w-[550px] p-4 shadow-md flex flex-col justify-between"
              >
                <div>
                  {/* Conditional rendering based on header words */}
                  <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                    {card.header}{" "}
                  </h2>
                  <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                    {card.header2}{" "}
                  </h2>
                </div>
                <div className="flex justify-between items-center">
                  <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                    Click Here
                  </button>

                  <div className="bg-primary w-[13px] h-[37px] rounded-xl"></div>
                </div>
              </div>
            </Link>
          ) : null
        )}
      </div>
    </div>
  );
}

export default Attendance;
