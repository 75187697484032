import React, { useState, useEffect } from "react";

const RejectedJobs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [data, setData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/job/createdjobapproveORreject?status=REJECTED`
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching rejected jobs:", error);
      });
  }, []);

  // Pagination
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const maxPageNumbers = 5;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md mr-2 ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Filter data based on pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const filteredData = data.slice(startIndex, endIndex);

  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl">
        Rejected Jobs
      </p>
      <div className="flex mt-10">
        <table className="border-collapse border border-gray-200 rounded-lg ml-6">
          <thead>
            <tr className="bg-[#99BC85]">
              <th className="border border-gray-200 px-10 py-4 w-[520px] text-center">
                Rejected Job
              </th>
              <th className="border border-gray-200 px-10 py-4 w-[520px] text-center">
                Reason for Rejection
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={item._id} className="border border-gray-200 px-4 py-2">
                <td className="text-center">{item.job_name}</td>
                <td className="text-center">{item.job_reject_reason}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevious}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
      {/* End Pagination */}
    </div>
  );
};

export default RejectedJobs;
