 

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Inventory_Matched from "./Inventory_Matched";
import NonApprovedRows from "./NonApprovedRows.jsx";
import ShortageItems from "./ShortageItems.jsx";
import usePermissions from "../../../components/permissions/permission.jsx";

const StartJob = () => {
  const { hasPermission } = usePermissions();
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [popupJobName, setPopupJobName] = useState("");
  const [jobId, setJobId] = useState("");
  const [productionData, setProductionData] = useState([]);
  const [displayInventoryPopup, setDisplayInventoryPopup] = useState(false);
  const [nonApprovedPopup, setNonApprovedPopup] = useState(false);
  const [shortageItems, setShortageItems] = useState(null);
  const [rawItemData, setRawItemData] = useState(null); // State to hold raw item data
  const { REACT_APP_API_ENDPOINT } = process.env;
  const navigate = useNavigate();
  const itemsPerPage = 10;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/job/createdjobapproveORreject?status=APPROVE`
    )
      .then((response) => response.json())
      .then((data) => {
        const mappedData = data.map(
          ({
            _id,
            job_name,
            job_quantity,
            job_priority_level,
            recipe_dr,
            job_create_at,
          }) => ({
            jobid: _id,
            jobName: job_name,
            jobQuantity: job_quantity,
            priority: job_priority_level,
            doctor: recipe_dr,
            createdDate: new Date(job_create_at).toLocaleString(),
          })
        );
        setProductionData(mappedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const totalPages = Math.ceil(productionData.length / itemsPerPage);
  const maxPageNumbers = 5;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const openPopup = (jobName, _id) => {
    setPopupJobName(jobName);
    setJobId(_id);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleCheck = () => {
    const startDate = document.getElementById("start-date").value;
    const endDate = document.getElementById("end-date").value;
    const productionManager =
      document.getElementById("production-manager").value;
    const note = document.getElementById("note").value;

    const requestBody = {
      job_id: jobId,
      production_estimate_start_date: startDate,
      production_estimate_end_date: endDate,
      production_manager: productionManager,
      production_request_discription: note,
      production_create_by: "EMP-004",
      production_complete_percentage: "0",
    };

    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/productionlevel/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to add production level");
        }
      })
      .then((data) => {
        const productionId = data.production_id;
        console.log("Production ID:", productionId);
        console.log("Response Data:", data); // Log the response data
        const { nonApprovedRows, rawItemData, shortageItems } = data; // Declaration of shortageItems
        // Close any open popups
        setShowPopup(false);
        setNonApprovedPopup(false);
        setDisplayInventoryPopup(false);
        setShortageItems(false);

        if (nonApprovedRows && nonApprovedRows.length > 0) {
          setNonApprovedPopup(true);
          setTimeout(() => {
            setNonApprovedPopup(false);
          }, 3000);
        }
        if (data.shortageItems && data.shortageItems.length > 0) {
          setShortageItems(data.shortageItems);

          // setTimeout(() => {
          //     setShortageItems(null);
          // }, 300000);
        } else if (rawItemData && rawItemData.length > 0) {
          setDisplayInventoryPopup(true);
          // const { production_id } =
          //   rawItemData;
          setTimeout(() => {
            setDisplayInventoryPopup(false);
            navigate("/create-production", {
              state: { productionId: productionId },
            });
          }, 3000);
        } else {
          // const { production_id } = data;
          navigate("/start-production", {
            state: { productionId: productionId },
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const filteredData = productionData.slice(startIndex, endIndex);

  return (
    <div className=" bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl">
        Start Production
      </p>
      <div className="flex mt-10">
        <div className="overflow-y-auto max-h-[500px] w-full">
          <table className="overflow-y-auto h-screenborder-collapse border border-gray-200 rounded-lg ml-6 ">
            <thead>
              <tr className="bg-[#99BC85]">
                <th className="border border-gray-200 px-6 py-4 w-[200px] text-center">
                  Job ID
                </th>
                <th className="border border-gray-200 px-6 py-4 w-[200px] text-center">
                  Job Name
                </th>
                <th className="border border-gray-200 px-6 py-4 w-[200px] text-center">
                  Job Quantity
                </th>
                <th className="border border-gray-200 px-6 py-4 w-[200px] text-center">
                  Priority
                </th>
                <th className="border border-gray-200 px-6 py-4 w-[200px] text-center">
                  Doctor
                </th>
                <th className="border border-gray-200 px-6 py-4 w-[200px] text-center">
                  Created Date
                </th>
                <th className="border border-gray-200 px-6 py-4 w-[200px] text-cente mtr">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr
                  key={item.jobid}
                  className="border border-gray-200 px-4 py-2"
                >
                  <td className="text-center">{item.jobid}</td>
                  <td className="text-center">{item.jobName}</td>
                  <td className="text-center">{item.jobQuantity}</td>
                  <td className="text-center">{item.priority}</td>
                  <td className="text-center">{item.doctor}</td>
                  <td className="text-center">{item.createdDate}</td>
                  <td className="text-center">
                    {hasPermission(1420) && (
                      <button
                        className="bg-[#D4E7C5] text-black px-3 py-1 rounded-md"
                        onClick={() => openPopup(item.jobName, item.jobid)}
                      >
                        Start
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevious}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg">
            <h2 className="text-lg font-bold mb-4">Start Production</h2>
            <div className="mb-4">
              <label className="block mb-2">Job ID:</label>
              <input
                type="text"
                className="border border-gray-300 p-2 w-full"
                value={jobId}
                readOnly
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Job Name:</label>
              <input
                type="text"
                className="border border-gray-300 p-2 w-full"
                value={popupJobName}
                readOnly
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Start Date:</label>
              <input
                id="start-date"
                type="date"
                className="border border-gray-300 p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">End Date:</label>
              <input
                id="end-date"
                type="date"
                className="border border-gray-300 p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Production Manager:</label>
              <select
                id="production-manager"
                className="border border-gray-300 p-2 w-full"
              >
                <option value="">Select Production Manager</option>
                <option value="manager1">Manager 1</option>
                <option value="manager2">Manager 2</option>
                <option value="manager3">Manager 3</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Note:</label>
              <textarea
                id="note"
                className="border border-gray-300 p-2 w-full"
                rows="4"
              ></textarea>
            </div>
            <div className="flex justify-center">
              <button
                onClick={handleCheck}
                className="bg-[#D4E7C5] text-black px-4 py-2 rounded-md mr-2"
              >
                Check
              </button>
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded-md"
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {displayInventoryPopup && (
        <Inventory_Matched
          rawItemName={rawItemData?.raw_item_name}
          rawItemQuantity={rawItemData?.raw_item_quantity}
        />
      )}
      {nonApprovedPopup && <NonApprovedRows />}
      {shortageItems && (
        <ShortageItems
          shortageItems={shortageItems}
          onClose={() => setShortageItems(null)}
        />
      )}
    </div>
  );
};

export default StartJob;
