 

import React, { useEffect, useState } from "react";
import "./inventoryDashboard.css";
import axios from "axios";

import Cube from "../../../Assets/icons/Cube-2.png";
import Sphere from "../../../Assets/icons/Sphere-Green-Glossy.png";
import Toroid from "../../../Assets/icons/SuperToroid-2.png";

import Card1 from "../../../components/card 1/card1";
import usePermissions from "../../../components/permissions/permission";

export default function InventoryDashboard() {
  const { hasPermission } = usePermissions();

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string

  //get all available items
  const [AvailableItems, setAvailableItems] = useState([]);
  const [TotalAvailableItems, setTotalAvailableItems] = useState([]);
  const GetAllAvailableItems = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/inventory/inventory/Shadow/Branch/Status/SellStatus/BRANCH-06247866/ACTIVE/true`
      );
      console.log(res.data);
      setAvailableItems(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    GetAllAvailableItems();
  }, []);

  useEffect(() => {
    calculateTotalQtyPerItem(AvailableItems);
  }, [AvailableItems]);

  function calculateTotalQtyPerItem(items) {
    const itemTotals = {};
    items.forEach((item) => {
      const itemName = item.item_name;
      const itemQty = parseInt(item.shadow_qty);
      if (itemTotals[itemName]) {
        itemTotals[itemName] += itemQty;
      } else {
        itemTotals[itemName] = itemQty;
      }
    });

    // Convert itemTotals object into an array of objects
    const resultArray = Object.keys(itemTotals).map((key) => ({
      name: key,
      qty: itemTotals[key],
    }));
    setTotalAvailableItems(resultArray);
    console.log(TotalAvailableItems);
  }

  //inventory request
  const [requestDate, setRequestDate] = useState(formattedDate.split("T")[0]);
  const [requests, setRequests] = useState([]);
  useEffect(() => {
    const GetRequests = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/inventoryRequest/requests/date/${requestDate}`
        );
        console.log(res.data);
        setRequests(res.data);
      } catch (error) {}
    };
    GetRequests();
  }, [requestDate]);

  return (
    <div className="p-10 bg-background">
      <p className="header-1">Inventory Dashboard</p>

      <div className="flex justify-between items-center w-full">
        {hasPermission(1280) && (
          <Card1
            title1="Total "
            title2="available"
            subTitle="Item Qty."
            value={TotalAvailableItems.length}
            img={Cube}
          />
        )}{" "}
        {hasPermission(1281) && (
          <Card1
            title1="Total "
            title2=""
            subTitle="Item Qty."
            value="22"
            img={Sphere}
          />
        )}
        {hasPermission(1282) && (
          <Card1
            title1="Total "
            title2=""
            subTitle="Item Qty."
            value="22"
            img={Toroid}
          />
        )}
      </div>

      <div className="w-full flex justify-end items-center gap-5 mt-10">
        <p className="label-1">Date </p>
        <p className="label-1">:</p>
        <input
          className="w-[259px] h-[53px] rounded-[46px] border px-5"
          type="date"
          value={requestDate}
          onChange={(e) => {
            setRequestDate(e.target.value);
          }}
        />
      </div>

      <div className="w-full h-[240px] bg-[#F3F8FF] rounded-[46px] flex flex-col justify-start my-10">
        <div className="w-full h-[72px] rounded-[46px] bg-[#BFD8AE] flex justify-around items-center px-5 pr-[35px]">
          <p className="font-bold text-[24px] text-[#656565] text-center w-3">
            #
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[350px]">
            Request Id
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
            Request Date
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
            Status
          </p>
        </div>
        <div className="w-ful h-[130px] flex flex-col justify-around items-center px-5 overflow-y-scroll ">
          {requests.length > 0
            ? requests.map((bill, index) => {
                return (
                  <div
                    key={index}
                    className="w-full  flex justify-around items-center  "
                  >
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-3">
                      {index + 1}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-[350px]">
                      {bill.inventory_request_id}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
                      {bill.inventory_request_date.split("T")[0]}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-[100px]">
                      {bill.inventory_request_status}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </div>

      <div className="w-full h-[640px] bg-[#F3F8FF] rounded-[46px] flex flex-col justify-start my-10">
        <div className="w-full h-[72px] rounded-[46px] bg-[#BFD8AE] flex justify-around items-center px-5 pr-[35px]">
          <p className="font-bold text-[24px] text-[#656565] text-center w-3">
            #
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[650px]">
            Item Name
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
            Available Qty.
          </p>
        </div>
        <div className="w-ful h-[530px] flex flex-col justify-around items-center px-5 overflow-y-scroll ">
          {TotalAvailableItems.length > 0
            ? TotalAvailableItems.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full  flex justify-around items-center  "
                  >
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-3">
                      {index + 1}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-left  w-[650px]">
                      {item.name}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
                      {item.qty}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}
