 

import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import "./updateItemCategory.css";
import Arrow from "./../../../Assets/icons/down-arrow.png";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";

export default function UpdateItemCategory() {
  const { hasPermission } = usePermissions();
  const [resultShow, setResultShow] = useState(false);

  const [results, setResults] = useState([]);
  const CategorySearchHandler = async (e) => {
    if (e !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/itemCategory/status-category/ACTIVE/${e}`
        );
        console.log(res.data);
        const options = res.data.map((category) => ({
          value: category.item_category_id,
          label: category.item_category_name,
        }));
        setResults(options);
        return options;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [results2, setResults2] = useState({
    item_category_description: "",
    item_category_id: "",
    item_category_name: "",
    item_category_status: "",
    item_category_update_date: "2",
    item_category_update_user_id: "",
  });
  const GetItemCategory = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/itemCategory/category/item_id/${id.value}`
      );
      console.log(res.data);
      setResults2(res.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateItemCategoryHandler = async () => {
    if (
      results2.item_category_name !== "" &&
      results2.item_category_status !== ""
    ) {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/itemCategory/update?id=${results2.item_category_id}`,
          results2
        );
        console.log(res.data);
      } catch (error) {}
    } else {
      alert("please fill all field");
    }
  };

  const CancelHandler = () => {
    setResults2({
      item_category_description: "",
      item_category_id: "",
      item_category_name: "",
      item_category_status: "",
      item_category_update_date: "0",
      item_category_update_user_id: "",
    });
  };

  const DeleteHandler = async () => {
    alert("are you sure?");
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/itemCategory/delete/${results2.item_category_id}`
      );
      console.log(res.data);
    } catch (error) {}
  };
  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      borderColor: state.isFocused ? "grey" : "red",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };
  return (
    <div className="p-10 bg-background">
      <p className="header-1">View & Update Item Category</p>
      <div className="flex gap-10 items-center py-5 px-10 w-[800px] mt-10 bg-[#F3F8FF] rounded-[46px] border border-[#00000036]">
        <p className="label-1"> Search Item Category</p>
        <p className="label-1">:</p>
        {hasPermission(1640) && (
          <AsyncSelect
            className="w-80"
            styles={customStyles}
            cacheOptions
            loadOptions={CategorySearchHandler}
            // defaultOptions
            onChange={GetItemCategory}
            value={results}
          />
        )}
      </div>
      <div className="w-full flex items-center justify-center ">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Update Item Category Details</p>
          <div className="flex flex-col ">
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Category Id</p>
              <p className="label-1">:</p>
              <input
                className="input-1 "
                value={results2.item_category_id}
                onChange={() => {}}
                readOnly
              />
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Category Name</p>
              <p className="label-1">:</p>
              <input
                className="input-1 "
                value={results2.item_category_name}
                onChange={(e) => {
                  const data = { ...results2 };
                  data.item_category_name = e.target.value;
                  setResults2(data);
                }}
              />
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Category Description</p>
              <p className="label-1">:</p>
              <input
                className="input-1 "
                value={results2.item_category_description}
                onChange={(e) => {
                  const data = { ...results2 };
                  data.item_category_description = e.target.value;
                  setResults2(data);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className=" flex items-center justify-end gap-x-6 mb-10 px-[150px]">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
          onClick={CancelHandler}
        >
          <p className="p2 text-white">Cancel</p>
        </button>
        {hasPermission(1660) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
            onClick={DeleteHandler}
          >
            <p className="p2 text-white">Delete</p>
          </button>
        )}
        {hasPermission(1650) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
            onClick={UpdateItemCategoryHandler}
          >
            <p className="p2 text-white">Update</p>
          </button>
        )}
      </div>
    </div>
  );
}
