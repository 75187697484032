import React, { useEffect, useState } from "react";
import "./bill.css";
import axios from "axios";
import BillPreview1 from "../../../components/bill preview 1/billPreview1";

export default function Bill() {
  const [branchId, setBranchId] = useState("BRANCH-0001");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [billId, setBillId] = useState("");
  const [billPreviewShow, setBillPreviewShow] = useState(false);
  const [billData, setBillData] = useState([]);

  // Invoice number search
  const BillSearchHandler = async (e) => {
    if (e.target.value !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/pos/search/BillId/Branch/PosType/${e.target.value}/${branchId}/BRANCH-POS`
        );
        console.log(res.data);
        let bills = res.data;
        setBillData(bills);
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Date range search
  const BillSearchDateHandler = async (e) => {
    if (e.target.id === "dateFrom") {
      setDateFrom(e.target.value);
    } else if (e.target.id === "dateTo") {
      setDateTo(e.target.value);
    }
  };

  useEffect(() => {
    const fetchBillsByDateRange = async () => {
      if (dateFrom !== "" && dateTo !== "") {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/pos/search/RateRanged/Branch/PosType/${dateFrom}/${dateTo}/${branchId}/BRANCH-POS`
          );
          console.log(res.data);
          let bills = res.data;
          setBillData(bills);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchBillsByDateRange();
  }, [dateFrom, dateTo, branchId]);

  const BillSelectHandler = async (id) => {
    console.log(id);
    setBillId(id);
    setBillPreviewShow(true);
  };

  return (
    <div className="p-10 bg-background h-screen overflow-y-auto">
      <p className="header-1">Pos Bills</p>
      <div className="w-full flex items-center justify-between my-10">
        <div className="w-[350px] flex items-center justify-between">
          <p className="label-1">Bill Id :</p>
          <input
            type="text"
            className="w-[228px] h-[53px] rounded-[46px] bg-white border border-[#797979] px-5"
            onChange={(e) => BillSearchHandler(e)}
          />
        </div>
        <div className="w-[800px] flex items-center justify-between">
          <div className="w-[350px] flex items-center justify-between">
            <p className="label-1">From :</p>
            <input
              className="w-[228px] h-[53px] rounded-[46px] bg-white border border-[#797979] px-5"
              type="date"
              id="dateFrom"
              value={dateFrom}
              onChange={(e) => BillSearchDateHandler(e)}
            />
          </div>
          <div className="w-[350px] flex items-center justify-between">
            <p className="label-1">To :</p>
            <input
              className="w-[228px] h-[53px] rounded-[46px] bg-white border border-[#797979] px-5"
              type="date"
              id="dateTo"
              value={dateTo}
              onChange={(e) => BillSearchDateHandler(e)}
            />
          </div>
        </div>
      </div>
      <div className="bg-[#F3F8FF] w-full min-h-96 rounded-t-[40px] rounded-b-[50px]">
        <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around mr-5">
          <div className="w-10 flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center">#</p>
          </div>
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center">Bill ID</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center">Issued Date</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center">Customer</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
        </div>
        <div className="max-h-[300px] overflow-y-scroll mr-5">
          {billData.length > 0
            ? billData.map((bill, index) => {
                return (
                  <div
                    key={index}
                    className="w-full flex items-start justify-around my-3"
                    onClick={() => BillSelectHandler(bill.pos_id)}
                  >
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[350px] p1 text-[#656565] text-center">
                      {bill.pos_id}
                    </p>
                    <p className="w-[350px] p1 text-[#656565] text-center">
                      {bill.pos_date}
                    </p>
                    <p className="w-[350px] p1 text-[#656565] text-center">
                      {bill.customer_name}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <div className="w-full flex justify-end my-10">
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#881d1d] border rounded-[23px] px-4">
          <p className="p2 text-white">Cancel</p>
        </button>
      </div>
      <div className="w-full pr-[110px] ml-[-38px] absolute top-0">
        {billPreviewShow ? (
          <BillPreview1
            id={billId}
            CancelHandler={() => setBillPreviewShow(false)}
          />
        ) : null}
      </div>
    </div>
  );
}
