 

import React, { useState, useEffect, useRef } from "react";
import AsyncSelect from "react-select/async";
import { useReactToPrint } from "react-to-print";
import "./pos.css";
import "./../../../App.css";
import Arrow from "../../../Assets/icons/drop down correct 1.png";
import Printer from "../../../Assets/icons/print 1.png";
import IdGenerate from "../../../utils/id_generate";
import axios from "axios";
import Alert from "../../../components/alert";
import BillLogo from "../../../Assets/icons/drug-logo.png";
import usePermissions from "../../../components/permissions/permission";

export default function Pos() {
  const { hasPermission } = usePermissions();
  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [userId, setUserId] = useState("USER-000000");
  const [userName, setUserName] = useState("unknown");
  const [creditBalance, setCreditBalance] = useState(0);

  const [creditLimit, setCreditLimit] = useState(0);

  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [cash, setCash] = useState(null);
  const [credit, setCredit] = useState(null);
  const [balance, setBalance] = useState(0);

  const [tableData, setTableData] = useState([]);

  const [invoiceNo, setInvoiceNo] = useState(IdGenerate("INVOICE"));
  const [branch, setBranch] = useState("BRANCH-0001");

  // Define state for selected item
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemSearch = async (inputValue) => {
    if (inputValue !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/inventory/searchInventory/ItemName/InventoryStatus/ShadowQty/Branch/${inputValue}/ACTIVE/BRANCH-06247866`
        );
        console.log(res.data);

        // Extract relevant data from response to populate options
        const options = res.data.map((item) => ({
          value: item.inventory_batch_id,
          label: item.item_name, // Customize label as needed
        }));
        // setItemOptions(options);
        // console.log(options)
        return options;
      } catch (error) {
        console.error(error);
      }
    } else {
      // setItemOptions([]);
      return [];
    }
  };
  const handleItemSelect = (selectedOption) => {
    // console.log(selectedOption);
    setSelectedItem(selectedOption);
    // Perform any other actions you need with the selected item
    GetItem(selectedOption.value);
  };

  const GetItem = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/inventory/inventory/InventoryBatchId/${id}`
      );

      console.log("Response data:", res.data);

      // Ensure that res.data is not empty and has the expected structure
      if (res.data && Array.isArray(res.data) && res.data.length > 0) {
        const itemData = res.data[0];
        console.log("Item data:", itemData);

        const Data = [...tableData]; // Create a copy of tableData
        Data.push({
          item_name: itemData.item_name,
          inventory_id: itemData.inventory_batch_id,
          item_available_qty: itemData.shadow_qty,
          item_qty: 0,
          item_price: parseFloat(itemData.item_unit_selling_price).toFixed(2),
          item_measure_unit: itemData.item_measure_unit,
          item_total: 0 * parseFloat(itemData.item_unit_selling_price),
        });

        console.log("Updated Data:", Data);

        setTableData(Data); // Update the state with the new data
      } else {
        console.error("Unexpected response data format or empty data array.");
      }
    } catch (error) {
      console.error("Error fetching item data:", error);
    }
  };

  const [itemArrow, setItemArrow] = useState(false);

  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      borderColor: state.isFocused ? "grey" : "red",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  const customStylesItems = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "transparent",
      borderRadius: "100px",
      width: "900px",
      borderColor: state.isFocused ? "red" : "blacks",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "item name",
      color: "white",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
      display: "none",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
      width: "122%",
      position: "absolute",
      zIndex: "100",
      top: "30px",
      left: "-160px",
      borderRadius: "20px",
      overflow: "hidden",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  //customer
  const [customerName, setCustomerName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customerType, setCustomerType] = useState("");

  const [customers, setCustomers] = useState([]);
  const CustomerSearchHandler = async (inputValue) => {
    // console.log(inputValue)
    // setCustomerName(inputValue);
    if (inputValue !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/customer/search/name/contact/status/${inputValue}/ACTIVE`
        );
        // console.log(res.data)
        const options = res.data.map((customer) => ({
          value: customer.customer_id,
          label: customer.customer_name,
        }));
        setCustomers(options);
        return options;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
  };
  const CustomerSelectHandler = async (customer) => {
    // console.log(customer)

    setCustomerName(customer.label);
    setCustomerId(customer.value);
    GetCustomer(customer.value);

    const options = {
      value: customer.value,
      label: customer.label,
    };
    setCustomers(options);
  };

  const GetCustomer = async (customerId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/customer/get/id/${customerId}`
      );
      console.log(res.data);
      setCreditBalance(res.data[0].available_credit_limit || 0);
      setCreditLimit(res.data[0].credit_limit || 0);
      setCustomerType(res.data[0].customer_payment_method);
    } catch (error) {}
  };
  const GetCustomerDiscount = async (id, value) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/customer/get/discount/id/value/${id}/${value}`
      );
      // console.log(res.data)
      setDiscount(parseInt(res.data[0].rate));
    } catch (error) {}
  };

  const EnterHandler = async () => {
    const data = {
      pos_id: invoiceNo,
      branch_id: branch,
      pos_date: formattedDate,
      pos_user_id: userId,
      pos_type: "BRANCH-POS",
      customer_id: customerId,
      pos_status: "CLOSED",
      pos_sub_total: subTotal,
      pos_discount: discount,
      pos_net_total: netTotal,
      pos_cash: cash,
      pos_credit: credit,
      pos_balance: balance,
      item_data: tableData,
    };

    console.log(data);
    //check conditions
    if (creditLimit < credit) {
      alert("Credit Limit Exceed");
    } else if (balance < 0) {
      alert("Cash is less than balance");
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/pos/addPos`,
          data
        );
        console.log(res.data);
        if (res.status === 200 || res.status === 201) {
          handlePrint(null, () => contentToPrint.current);
          // alert('Bill Added Successfully');
          setAlert({
            status: "success",
            alert: "Bill Added Successfully",
            show: true,
          });
          // setTableData([])
          setCustomerName("");
          setCustomerId("");
          setInvoiceNo(IdGenerate("INVOICE"));
          setBranch("BRANCH-0001");
          setDiscount(0);
          setNetTotal(0);
          setCash(0);
        }
      } catch (error) {
        setAlert({
          status: "error",
          alert: "Something Went Wrong",
          show: true,
        });
      }
    }
  };
  const CancelHandler = () => {
    // setTableData([])
    setCustomerName("");
    setCustomerId("");
    setInvoiceNo(IdGenerate("INVOICE"));
    setBranch("BRANCH-0001");
    setSubTotal(0);
    setDiscount(0);
    setNetTotal(0);
    setCash(0);
    setBalance(0);
  };

  //customer close
  const [customerShow, setCustomerShow] = useState(false);
  const CustomerAddHandler = () => {
    setCustomerShow(true);
  };

  //total

  useEffect(() => {
    let total = 0;
    tableData.forEach((item) => {
      total += item.item_total;
    });
    setSubTotal(total);
  }, [tableData]);

  //discount
  useEffect(() => {
    if (customerId !== "") {
      GetCustomerDiscount(customerId, subTotal);
    }
  }, [subTotal, customerId]);

  //net total
  useEffect(() => {
    setNetTotal(subTotal - (discount * subTotal) / 100);
    // console.log(netTotal)
  }, [subTotal, discount]);

  //change
  useEffect(() => {
    setBalance(cash + credit - netTotal);
  }, [cash, netTotal, credit]);

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  //customer
  const [id, setId] = useState(IdGenerate("CUSTOMER"));
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [dob, setDob] = useState("");
  const [mail, setMail] = useState("");

  const AddCustomer = async () => {
    const data = {
      customer_id: id,
      customer_name: name,
      customer_contact_no: contact,
      customer_dob: dob,
      customer_email: mail,
      category: "unregistered",
      customer_update_user_id: userId,
      customer_update_date: formattedDate,
      discount: [],
    };

    if (name === "" || contact === "" || dob === "" || mail === "") {
      setAlert({
        status: "error",
        alert: "Please fill the required fields",
        show: true,
      });
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/customer/add`,
          data
        );
        console.log(res.data);
        if (res.status === 201 || 200) {
          setAlert({
            status: "success",
            alert: "Customer added successfully",
            show: true,
          });
          setId(IdGenerate("CUSTOMER"));
          setName("");
          setContact("");
          setDob("");
          setMail("");
          setCustomerShow(false);
        }
      } catch (error) {
        setAlert({
          status: "error",
          alert: "Customer already exist",
          show: true,
        });
      }
    }
  };

  const GetUser = async (id) => {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/user/get/${id}`
    );
    console.log(res.data);
    setUserName(res.data[0].user_name);
  };
  useEffect(() => {
    GetUser(userId);
  }, []);

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <Alert
        show={alert.show}
        alert={alert.alert}
        status={alert.status}
        ok={() => {
          //reload
          window.location.reload();
          setAlert({
            status: "",
            alert: "",
            show: false,
          });
        }}
      />
      <p className="header-1 ml-10 mt-5"> Point of Sale</p>
      <div className="w-full flex gap-x-10 items-center justify-between px-16 my-14">
        <div className=" flex items-center content-between gap-x-10">
          <p className="label-1">Invoice No :</p>
          <input
            className="input-1 "
            type="text"
            value={invoiceNo}
            onChange={(e) => setInvoiceNo(e.target.value)}
          />
        </div>
        <div className=" flex items-center content-between gap-x-10">
          <p className="label-1">Customer :</p>
          <AsyncSelect
            className="w-80"
            styles={customStyles}
            cacheOptions
            loadOptions={CustomerSearchHandler}
            // defaultOptions
            onChange={CustomerSelectHandler}
            value={customers}
          />
          {hasPermission(1070) && (
            <button
              onClick={() => CustomerAddHandler()}
              className="flex items-center justify-center w-[102px] h-[53px] bg-transparent border-2 border-[#727272] rounded-[100px] text-lg font-bold"
            >
              Add
            </button>
          )}
        </div>
      </div>
      <div className="w-full flex items-center justify-center px-12">
        <div className="bg-PrimaryColor1 w-full h-[82px] rounded-[100px] mx-20 flex items-center justify-between">
          <p className="label-1 ml-10 text-white">Item name :</p>
          <AsyncSelect
            styles={customStylesItems}
            cacheOptions
            loadOptions={handleItemSearch}
            onMenuOpen={() => setItemArrow(true)}
            onMenuClose={() => setItemArrow(false)}
            onChange={handleItemSelect}
            value={selectedItem}
          />
          <img
            src={Arrow}
            alt="arrow"
            className={
              itemArrow
                ? "mr-7 size-6 transition duration-500 fill-white"
                : "mr-7 size-6 transition rotate-180 duration-500"
            }
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-center my-10">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Available Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[110px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Price</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Quantity</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[70px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Unit</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[150px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Total</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Action</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="">
            {tableData.length > 0
              ? tableData.map((item, index) => (
                  <div
                    key={index}
                    className="w-full flex items-start justify-around my-3 "
                  >
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[350px] p1 text-[#656565] ">
                      {item.item_name}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.item_available_qty}
                    </p>
                    <p className="w-[110px] p1 text-[#656565] text-center">
                      {item.item_price}
                    </p>
                    <p className="w-[120px] p1 text-[#656565] text-center">
                      <input
                        type="number"
                        value={item.item_qty}
                        className={
                          item.item_available_qty < item.item_qty
                            ? "border-[#ef0e0ef7] rounded-2xl w-full border-4 px-1 focus:outline-none"
                            : "focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                        }
                        onChange={(e) => {
                          let temp = [...tableData];
                          temp[index].item_qty = e.target.value; // Convert to float
                          temp[index].item_total =
                            temp[index].item_qty * temp[index].item_price;
                          setTableData(temp);
                        }}
                      />
                    </p>
                    <p className="w-[70px] p1 text-[#656565] text-center">
                      {item.item_measure_unit}
                    </p>
                    <p className="w-[150px] p1 text-[#656565] text-center">
                      {item.item_total.toFixed(2)}
                    </p>
                    <p className="w-[120px] p1 text-[#656565] text-center">
                      <button
                        className="w-[120px] h-[46px] rounded-[100px] bg-[#EDC2C2]"
                        onClick={() => {
                          let temp = [...tableData];
                          temp.splice(index, 1);
                          setTableData(temp);
                        }}
                      >
                        Delete
                      </button>
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-start px-16 ">
        <div className="w-[585px]  rounded-[23px] bg-[#F3F8FF] px-10 py-7 flex flex-col justify-between gap-y-7">
          <div className="w-full flex items-center justify-between gap-10">
            <p className="p1 text-[#656565] w-[500px]">Customer Name:</p>
            <div className="relative w-[213px]">
              <p className="p1 text-[#656565] w-70 border-2 border-[#00000059] h-9 rounded-[100px] px-7 bg-white overflow-hidden truncate hover:overflow-visible group-hover:whitespace-nowrap">
                {customerName}
              </p>
            </div>
          </div>

          <div className="w-full flex items-center justify-between">
            <p className="p1 text-[#656565]">Credit Limit :</p>
            <p className="p1 text-[#656565] border-2 border-[#00000059] h-9 w-70 rounded-[100px] px-7 bg-white">
              {creditLimit.toFixed(2)} LKR
            </p>
          </div>
          <div className="w-full flex items-center justify-between">
            <p className="p1 text-[#656565]">Credit Balance :</p>
            <p className="p1 text-[#656565] border-2 border-[#00000059] h-9 w-70 rounded-[100px] px-7 bg-white">
              {creditBalance.toFixed(2)} LKR
            </p>
          </div>
        </div>
        <div className="w-[350px] h-full  flex flex-col gap-y-2">
          <div className="w-[350px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Sub Total</p>
              <p className="p1 text-[#656565]">{subTotal.toFixed(2)}</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Discount</p>
              <p className="p1 text-[#656565]"> {discount}</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Net Total</p>
              <p className="p1 text-[#656565]">{netTotal.toFixed(2)}</p>
            </div>

            {customerType !== "credit" && (
              <div className="flex items-center justify-between">
                <p className="p1 text-[#656565]"> Cash</p>
                <input
                  className="w-[130px] font-bold h-7 rounded-[100px] border-[#00000059] border-2 bg-white px-2 text-end"
                  type="number"
                  value={cash}
                  onChange={(e) => setCash(parseFloat(e.target.value))}
                  disabled={customerType === "credit"}
                />
              </div>
            )}

            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Credit</p>
              <input
                className={
                  creditBalance >= credit
                    ? "w-[130px] font-bold h-7 rounded-[100px] border-[#00000059] border-2 bg-white px-2 text-end"
                    : "w-[130px] h-7 rounded-[100px] border-[#cc0b0b59] focus:outline-red-700 border-2 bg-white px-2 text-end"
                }
                type="number"
                value={credit}
                onChange={(e) => setCredit(parseFloat(e.target.value))}
              />
            </div>
          </div>
          <div className="w-[350px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Balance</p>
              <p className="p1 text-[#656565]">{balance.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-end gap-x-6 my-10 px-16">
        <button className="w-[138px] h-[41px] flex items-center justify-between bg-white border rounded-[23px] px-5">
          <img src={Printer} alt="" />
          <p
            className="p2 text-[#656565]"
            onClick={() => {
              handlePrint(null, () => contentToPrint.current);
            }}
          >
            Print
          </p>
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4">
          <p className="p2 text-white" onClick={CancelHandler}>
            Cancel
          </p>
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4">
          <p className="p2 text-white" onClick={(e) => EnterHandler()}>
            Save
          </p>
        </button>
      </div>

      <div className="Container"></div>

      <div
        className={
          customerShow
            ? "w-full h-[100%] absolute top-0 bg-[#3F3F3F5E] z-20 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="bg-[#F3F8FF] px-20 py-10 rounded-[46px] flex flex-col items-center gap-y-10 ">
          <p className="p1">Add Customer</p>
          <div className="flex items-center justify-between w gap-x-10 ">
            <p className="label-1 w-[300px] text-start">Customer Name :</p>
            <input
              className="w-[434px] h-[46px] rounded-[23px] border border-[#3F3F3F5E] px-5 font-bold"
              placeholder="Enter Customer Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w gap-x-10 ">
            <p className="label-1 w-[300px] text-start">Contact Number :</p>
            <input
              className="w-[434px] h-[46px] rounded-[23px] border border-[#3F3F3F5E] px-5 font-bold"
              placeholder="Enter Phone Number"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w gap-x-9">
            <p className="label-1 w-[300px] text-start">DoB :</p>
            <input
              type="date"
              className="w-[434px] h-[46px] rounded-[23px] border border-[#3F3F3F5E] px-5 font-bold"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w gap-x-10 ">
            <p className="label-1 w-[300px] text-start">Email :</p>
            <input
              className="w-[434px] h-[46px] rounded-[23px] border border-[#3F3F3F5E] px-5 font-bold"
              placeholder="Enter Email"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />
          </div>

          <div className="w-full flex items-center justify-end gap-x-8">
            <button
              className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
              onClick={() => {
                setCustomerShow(false);
              }}
            >
              Cancel
            </button>
            <button
              className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl"
              onClick={AddCustomer}
            >
              Add
            </button>
          </div>
        </div>
      </div>

      <div style={{ display: "none" }}>
        <div ref={contentToPrint}>
          <div className="bill-container">
            <div className="header">
              {/* <img src={BillLogo} alt="Bill Logo" className="bill-logo" /> */}
              <h1>Sri Lanka Ayurvedic Drugs Corporation</h1>
              <p>No.94 Old Kottawa Road Maharagama Sri Lanka</p>
              <p>Phone: 0112850229 Email: itsladc@gmail.com</p>
              <p>Website: www.sladc.lk</p>
            </div>
            <hr />
            <div className="invoice-details">
              <p>Invoice No: {invoiceNo}</p>
              <p>Date: {formattedDate}</p>
              <p>Cashier: {userName}</p>
            </div>
            <hr />
            <div className="customer-details">
              <p>Customer Name: {customerName}</p>
              <p>Contact: {customerName}</p>
              <p>Billing Address: {customerName}</p>
            </div>
            <hr />
            <table className="product-table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 &&
                  tableData.map((product, index) => (
                    <tr key={index}>
                      <td>{product.item_name}</td>
                      <td>{product.item_qty}</td>
                      <td>{product.item_price}</td>
                      <td>{product.item_price * product.item_qty}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <hr />
            <div className="totals">
              <p>Subtotal: {subTotal.toFixed(2)}</p>
              {/* <p>Tax: {tax}</p> */}
              <p>Discount: {discount}</p>
              <hr />
              <p>Grand Total: {netTotal.toFixed(2)}</p>
            </div>
            <hr />
            {/* <div className="payment-method">
        <p>Payment Method: {'paymentMethod'}</p>
      </div> */}
            <hr />
            <div className="footer">
              <p>Please check the products prior to purchase.</p>
              <p>Payments are non-refundable under any circumstance.</p>
              <p>
                We are not responsible for any damages or discrepancies reported
                after products have been dispatched.
              </p>
              <p>
                We do not accept returns or exchanges after the purchase has
                been completed.
              </p>
              <p>For any inquiries Contact us at: 0112850229</p>
              <h2>Thank you!</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
