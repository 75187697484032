import React, { useState, useEffect } from "react";
import UploadIcon from "../../../../Assets/icons/Upload.png";
import PDF from "../../../../Assets/icons/png.png"; // Assuming you have a PDF icon image

const ApplyLeave = ({ onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [empNo, setEmpNo] = useState("");
  const [fullName, setFullName] = useState("");
  const [requestedDate, setRequestedDate] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [leaveType, setLeaveType] = useState("");
  const [reason, setReason] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;

  const [errorMessage, setErrorMessage] = useState({
    empNo: "",
    fullName: "",
    requestedDate: "",
    reason: "",
  });

  // Function to handle file selection
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    // Fetch employee list from the server
    async function fetchEmployeeList() {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/employees/getemployeebasicdetails`
        );
        const data = await response.json();
        setEmployeeList(data);
      } catch (error) {
        console.error("Failed to fetch employee list:", error);
      }
    }
    fetchEmployeeList();
  }, [REACT_APP_API_ENDPOINT]);

  const handleSubmit = async () => {
    let isError = false;
    const errors = {
      empNo: "",
      fullName: "",
      requestedDate: "",
      reason: "",
    };

    if (!empNo) {
      errors.empNo = "Please enter Employee Number";
      isError = true;
    }

    if (!fullName) {
      errors.fullName = "Please enter Full Name";
      isError = true;
    }

    if (!requestedDate) {
      errors.requestedDate = "Please select Requested Date";
      isError = true;
    }

    if (!reason) {
      errors.reason = "Please enter Reason";
      isError = true;
    }

    if (isError) {
      setErrorMessage(errors);
      return;
    }

    setErrorMessage({ empNo: "", fullName: "", requestedDate: "", reason: "" });

    const formData = new FormData();
    formData.append("leave_approvel", selectedFile); // Assuming selectedFile is the file object
    formData.append("leave_type", leaveType);
    formData.append("employee_no", empNo);
    formData.append("requested_date", requestedDate);
    formData.append("reason", reason);

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/leave/addleave`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (response.ok) {
        setIsOpen(false); // Close the modal
        setSubmissionStatus("success");
        console.log("Leave added successfully!");
        onSuccess(); // Trigger the success callback to fetch the updated data
      } else {
        // Handle error
        setSubmissionStatus("error");
        console.error("Failed to add leave.");
      }
    } catch (error) {
      setSubmissionStatus("error");
      console.error("Error adding leave:", error);
    }
  };

  const handleEmployeeChange = (e) => {
    const selectedEmpNo = e.target.value;
    setEmpNo(selectedEmpNo);
    const selectedEmployee = employeeList.find(
      (employee) => employee.employee_no === selectedEmpNo
    );
    if (selectedEmployee) {
      setFullName(selectedEmployee.employee_name_initial);
    } else {
      setFullName("");
    }
  };

  return (
    <div>
      <div className="p-[50px]">
        <div>
          <div className="bg-white p-5 w-[354px] rounded-xl relative">
            <p className="font-bold font-sans text-[#797C80] text-[32px] ">
              Apply <br />
              Leave
            </p>

            <div className="flex justify-between items-center mt-2">
              <div>
                <button
                  className="bg-primary p-2 w-[151px] font-sans font-semibold rounded-[50px] text-white mt-2"
                  onClick={() => setIsOpen(true)}
                >
                  Apply
                </button>
              </div>

              <div>
                <div className="bg-primary p-2 rounded-[22px] h-[37px]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px]">
            <div className="mb-6 items-center justify-center">
              <p className="font-sans text-[32px] font-bold text-center">
                Enter Leave Details
              </p>

              <div className="mt-5 flex flex-col">
                <div className="flex flex-row items-center">
                  <div>
                    <p className="font-sans font-semibold">Leave Type :</p>
                  </div>

                  <div className="grid grid-cols-3 justify-between">
                    <div>
                      <div>
                        <label
                          htmlFor=""
                          className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                        ></label>
                        <select
                          id="leaveType"
                          name="leaveType"
                          value={leaveType}
                          onChange={(e) => setLeaveType(e.target.value)}
                          autoComplete="off"
                          className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                        >
                          <option value="" className="font-sans-bold">
                            Type
                          </option>
                          <option value="short leave">Short Leave</option>
                          <option value="half day">Half Day</option>
                          <option value="medical leave">Medical Leave</option>
                          <option value="annual leave">Annual Leave</option>
                          <option value="casual leave">Casual Leave</option>
                        </select>
                      </div>
                      {errorMessage.leaveType && (
                        <p className="text-red-500 text-sm mt-1">
                          {errorMessage.leaveType}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-row items-center">
                      <div className="ml-[59%]">
                        <p className="font-sans font-semibold ">EMP NO</p>
                      </div>
                      <div className="ml-[28%]">
                        <select
                          className={`w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white px-2 font-sans`}
                          value={empNo}
                          onChange={handleEmployeeChange}
                        >
                          <option value="">Select Employee</option>
                          {employeeList.map((employee) => (
                            <option
                              key={employee.id}
                              value={employee.employee_no}
                            >
                              {employee.employee_no}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div className="flex flex-row items-center mt-4">
                      <div>
                        <p className="font-sans font-semibold">Full Name :</p>
                      </div>
                      <div>
                        <input
                          className={`w-[300px] ml-8 h-10 rounded-[73px] py-1.5 bg-white px-2 font-sans ${
                            errorMessage.fullName ? "border-red-500" : ""
                          }`}
                          placeholder="Enter Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          readOnly
                        />
                        {errorMessage.fullName && (
                          <p className="text-red-500 text-sm mt-1 ml-10">
                            {errorMessage.fullName}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="flex flex-row items-center mt-4">
                      <div className="ml-[12%]">
                        <p className="font-sans font-semibold">
                          Requested Date :
                        </p>
                      </div>
                      <div>
                        <input
                          type="date"
                          className={`w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white px-2 font-sans ${
                            errorMessage.requestedDate ? "border-red-500" : ""
                          }`}
                          value={requestedDate}
                          onChange={(e) => setRequestedDate(e.target.value)}
                          min={new Date().toISOString().split("T")[0]} // Set min attribute to today's date
                        />

                        {errorMessage.requestedDate && (
                          <p className="text-red-500 text-sm mt-1 ml-10">
                            {errorMessage.requestedDate}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div className="flex flex-row items-center mt-4">
                      <div>
                        <p className="font-sans font-semibold">Reason :</p>
                      </div>
                      <div>
                        <textarea
                          className={`w-[500px] ml-8 h-24 py-1.5 bg-white px-2 font-sans ${
                            errorMessage.reason ? "border-red-500" : ""
                          }`}
                          placeholder="Enter Reason"
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                        />

                        {errorMessage.reason && (
                          <p className="text-red-500 text-sm mt-1 ml-10">
                            {errorMessage.reason}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="flex flex-row items-center mt-4">
                      <div className="ml-[12%]">
                        <p className="font-sans font-semibold">Attachment</p>
                      </div>
                      <div className="ml-10">
                        <input
                          type="file"
                          className="h-10 rounded-[30px] border border-[#9CAC8B]-300 border-input bg-white text-sm text-gray-400 file:border-0 file:bg-[#9CAC8B] file:text-white file:text-sm file:font-medium file:h-10"
                          onChange={handleFileSelect}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-5 space-x-3">
              <button
                className="bg-primary p-2 rounded-[22px] text-white"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                className="bg-gray-500 p-2 rounded-[22px] text-white"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplyLeave;
