 

import React, { useState, useEffect, useCallback, useMemo } from "react";

const Branch_Permission = ({ onPermissionsChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  //that shoulde change
  const permissionHierarchy = useMemo(
    () => ({
      5: { children: [140, 141] },
      141: { children: [1500, 1510] },
      // Add the rest of your permissions here
    }),
    []
  );

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (
            permissionHierarchy[key] &&
            permissionHierarchy[key].children.includes(value)
          ) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[5].children.includes(perm)
      ) &&
      !selectedPermissions.includes(5)
    ) {
      selectedPermissions.push(5);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange, permissionHierarchy]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  return (
    <div>
      <div>
        <div>
          <p
            className="font-bold text-[20px] ml-[20%] mt-4 underline"
            Value="5"
          >
            Branch
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p
                  className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black"
                  value="2"
                >
                  Add Branch
                </p>
              </div>
              <div>
                <p
                  className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black"
                  value="2"
                >
                  Update Branch
                </p>
                <div className="ml-5 mt-2">
                  <div className="ml-[9%]">
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-col gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="1500"
                            checked={!!checkedValues[1500]}
                            onChange={() => handleCheckboxChange(1500)}
                          />
                          Update
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="1510"
                            checked={!!checkedValues[1510]}
                            onChange={() => handleCheckboxChange(1510)}
                          />
                          Delete
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Branch_Permission;
