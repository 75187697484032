const PURCHASE_REQUEST_TABLE = () => {
  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 w-[96%] ml-10 max-h-[100%] overflow-y-auto mb-8 mt-6">
      <div className="w-full border-collapse border-none min-h-[250px]max-h-[50%] overflow-y-auto">
        <div className="text-left bg-white text-primary font-bold grid grid-cols-6 gap-4">
          <div className="px-6 py-6 ">Sample No</div>
          <div className="px-6 py-6 ">Drug Name</div>
          <div className="px-6 py-6 ">Symbol number</div>
          <div className="px-6 py-6 ">Date of Submission</div>
          <div className="px-6 py-6 ">
            Bidder/Supplier <br />
            Name
          </div>
          <div className="px-6 py-6 ">Status</div>
        </div>

        <div className=" grid grid-cols-6 gap-6 ml-2">
          <div className="border border-gray-400 rounded-[46px]">
            <input className=" text-center ml-2"></input>
          </div>
          <div className="border border-gray-400 rounded-[46px]">
            <input className=" text-center ml-2"></input>
          </div>
          <div className="border border-gray-400 rounded-[46px]">
            <input className=" text-center ml-2"></input>
          </div>
          <div className="border border-gray-400 rounded-[46px]">
            <input className=" text-center ml-2"></input>
          </div>
          <div className="border border-gray-400 rounded-[46px]">
            <input className=" text-center ml-2"></input>
          </div>
          <div className="border border-gray-400 rounded-[46px]">
            <input className=" text-center ml-2"></input>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PURCHASE_REQUEST_TABLE;
