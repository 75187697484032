 

import React, { useEffect, useState, useRef } from "react";
import AsyncSelect from "react-select/async";
import { useReactToPrint } from "react-to-print";
import "./inventoryPurchase.css";

import Arrow from "./../../../Assets/icons/down-arrow.png";
import axios from "axios";
import IdGenerate from "../../../utils/id_generate";
// import { socket } from '../../socket';

// import TopNaw from '../../components/top nav/topNaw';

import usePermissions from "../../../components/permissions/permission";

export default function InventoryPurchase() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");
  const [userName, setUserName] = useState("");
  const [branch, setBranch] = useState("Navinna");

  const [Po, setPo] = useState([]);
  const PoSearchHandler = async (e) => {
    if (e !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/purchase_order/search/po/id/status/${e}/ACTIVE`
        );

        console.log(res.data);
        const options = res.data.map((po) => ({
          value: po.po_id,
          label: po.po_id,
        }));
        setPo(options);
        return options;
      } catch (error) {
        if (error.response.status === 403) {
          alert("Forbidden");
        } else if (error.response.status === 401) {
          alert("Unauthorized");
        }
      }
    }
  };

  const [PoData, setPoData] = useState([
    {
      po_id: "",
      po_date: "",
      supplier_id: "",
      supplier_name: "",
    },
  ]);
  const [poItemsData, setPoItemsData] = useState([]);
  const GetPoData = async (po) => {
    console.log(po);
    //get po data
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/purchase_order/id/status/${po.value}/ACTIVE`
      );
      console.log(res.data);
      setPoData(res.data);
    } catch (error) {}
    //get po items
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/purchase_order/items/${po.value}`
      );
      console.log(res.data);
      // setPoData(res.data)
      res.data.map((item, index) => {
        const itemData = {
          item_id: item.item_id,
          item_name: item.item_name,
          requested_qty: item.item_qty,
          supplied_qty: 0,
          unit: item.item_measure_unit,
          storeName: "",
          storeId: "",
          remark: "",

          inventory_batch_id: IdGenerate("INVENTORY-BATCH"),
          po_id: item.po_id,
          inventory_purchase_date: formattedDate,
          inventory_purchase_user_id: userId,
          inventory_item_status: "WAITING",
          selling_item: "true",
        };
        setPoItemsData((prevState) => [...prevState, itemData]);
      });
    } catch (error) {}
  };

  const [stores, setStores] = useState([]);
  const GetStores = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/store/branch/BRANCH-06247866`
      );
      console.log(res.data);
      setStores(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    GetStores();
  }, []);

  const SubmitHandler = async () => {
    console.log(poItemsData);
    // let Data = poItemsData.map((item,index)=>{
    //     return {
    //         inventory_batch_id:IdGenerate('INVENTORY'),
    //         item_id: item.item_id,
    //         po_id: PoData[0].po_id,
    //         added_qty:item.supplied_qty,
    //         store_id:item.storeId,
    //         inventory_purchase_date:formattedDate,
    //         inventory_purchase_user_id:userId,
    //         inventory_item_description:item.remark,
    //         inventory_item_status:'WAITING',
    //         selling_item:'true'

    //     }
    // })

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/inventory/add`,
        poItemsData
      );
      console.log(res.data);
      if (res.status === 200 || res.status === 201) {
        alert("Inventory Added Successfully");
        handlePrint();
        window.location.reload();
      }
    } catch (error) {
      if (error.response.status === 409) {
        alert("Inventory Already Exist");
      } else if (error.response.status === 400) {
        alert("Inventory Not Added");
      } else if (error.response.status === 500) {
        alert("Internal Server Error");
      } else if (error.response.status === 404) {
        alert("Inventory Not Found");
      } else if (error.response.status === 403) {
        alert("Forbidden");
      } else if (error.response.status === 401) {
        alert("Unauthorized");
      }
    }
  };

  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      borderColor: state.isFocused ? "grey" : "red",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  return (
    <div className="p-10 bg-background">
      {/* <TopNaw moduleName ='Inventory Items Purchase' userName={userName}/> */}
      <p className="header-1">Inventory Purchase (GRN)</p>

      <div>
        <div className="flex items-center ">
          <p className="label-1">Purchase Order Id</p>
          <p className="label-1">:</p>
          <AsyncSelect
            className="w-80"
            styles={customStyles}
            cacheOptions
            loadOptions={PoSearchHandler}
            // defaultOptions
            onChange={GetPoData}
            value={Po}
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-center ">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1">Purchase Order Details</p>
          <div className="flex flex-col flex-wrap justify-between items-center">
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Purchase Order Id :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {PoData[0].po_id}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Supplier :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {PoData[0].supplier_name}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">PO Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {PoData[0].po_issue_date}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="header-1">Purchasing Items</p>

      <div className="w-[100%] flex items-center justify-center my-10 ">
        <div className="bg-[#F3F8FF] w-full min-h-96  rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Requested Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[110px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Supplied Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[150px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Store</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Unit</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Salable</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Remark</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="w-full h-[200px] flex flex-col gap-y-10">
            {poItemsData.length > 0
              ? poItemsData.map((item, index) => (
                  <div className="w-full  flex items-center justify-around">
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[350px] p1 text-[#656565]  text-center">
                      {item.item_name}
                    </p>
                    <p className="w-[160px] p1 text-[#656565]  text-centers">
                      {item.requested_qty}
                    </p>
                    <input
                      type="number"
                      className="w-[90px] border border-[#656565] mx-3"
                      value={item.supplied_qty}
                      onChange={(e) => {
                        const data = [...poItemsData];
                        data[index].supplied_qty = e.target.value;
                        setPoItemsData(data);
                      }}
                    />
                    <select
                      className="w-[130px] border border-[#656565] mx-3"
                      value={item.storeId}
                      onChange={(e) => {
                        const data = [...poItemsData];
                        const selectedStoreId = e.target.value;
                        // Find the corresponding store name for the selected store id
                        const selectedStore = stores.find(
                          (store) => store.store_id === selectedStoreId
                        );
                        if (selectedStore) {
                          // Set the storeName and storeId in the data array
                          data[index].storeName = selectedStore.store_name;
                          data[index].storeId = selectedStoreId;
                          setPoItemsData(data);
                        }
                      }}
                    >
                      <option value="">Select Store</option>
                      {stores.length > 0 &&
                        stores.map((store, index) => (
                          <option key={index} value={store.store_id}>
                            {store.store_name}
                          </option>
                        ))}
                    </select>

                    <p className="w-[120px] p1 text-[#656565] text-center">
                      {item.unit}
                    </p>
                    <select
                      className="w-[100px] border border-[#656565] mx-3"
                      value={item.selling_item}
                      onChange={(e) => {
                        const data = [...poItemsData];
                        data[index].selling_item = e.target.value;
                        setPoItemsData(data);
                      }}
                    >
                      <option value={""}>select</option>
                      <option value={"true"}>True</option>
                      <option value={"false"}>False</option>
                    </select>

                    <textarea
                      className="w-[170px] border border-[#656565] my-2"
                      value={item.remark}
                      onChange={(e) => {
                        const data = [...poItemsData];
                        data[index].remark = e.target.value;
                        setPoItemsData(data);
                      }}
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={handlePrint}
        >
          print
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl">
          Cancel
        </button>
        {hasPermission(1201) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl"
            onClick={SubmitHandler}
          >
            Submit
          </button>
        )}
      </div>

      {/* Print layout */}

      <div className="p-10 hidden">
        <div className="p-10" ref={contentToPrint}>
          <p className="header-1">Inventory Purchase (GRN)</p>

          <div id="printable" className="formal-document">
            <h1 className="document-title">Goods Received Note</h1>
            <div className="document-section">
              <h2 className="section-title">Purchase Order Details</h2>
              <div className="section-content">
                <p>
                  <strong>Purchase Order Id:</strong> {PoData[0].po_id}
                </p>
                <p>
                  <strong>Supplier:</strong> {PoData[0].supplier_name}
                </p>
                <p>
                  <strong>PO Date:</strong> {PoData[0].po_issue_date}
                </p>
              </div>
            </div>

            <div className="document-section">
              <h2 className="section-title">Purchasing Items</h2>
              <table className="items-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Item Name</th>
                    <th>Requested Qty</th>
                    <th>Supplied Qty</th>
                    <th>Unit</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {poItemsData.length > 0 &&
                    poItemsData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.item_name}</td>
                        <td>{item.requested_qty}</td>
                        <td>{item.supplied_qty}</td>
                        <td>{item.unit}</td>
                        <td>{item.remark}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
