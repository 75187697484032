 

import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import "./inventoryDispose.css";
import Arrow from "./../../../Assets/icons/down-arrow.png";
import IdGenerate from "../../../utils/id_generate";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";

export default function InventoryDispose() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");
  const [branchId, setBranchId] = useState("BRANCH-000000");

  const [resultsShow, setResultShow] = useState(false);

  const [disposeId, setDisposeId] = useState(IdGenerate("DISPOSE"));

  const [tableData, setTableData] = useState([]);

  const [items, setItems] = useState([]);
  const SearchItems = async (e) => {
    if (e !== "") {
      try {
        console.log(e);
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/inventory/searchInventory/ItemName/ShadowQty/Branch/${e}/BRANCH-06247866 `
        );
        console.log(res.data);
        setItems(res.data);
        const options = res.data.map((item) => ({
          value: item.inventory_batch_id,
          label: item.item_name, // Customize label as needed
        }));
        return options;
      } catch (error) {}
    }
  };

  const SelectItemHandler = async (inventory_id) => {
    setResultShow(false);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/inventory/inventory/InventoryBatchId/${inventory_id.value}`
      );
      console.log(res.data);
      if (res.data.length > 0) {
        res.data.map((item, index) => {
          const data = [...tableData];
          data.push({
            item_name: item.item_name,
            item_id: item.inventory_batch_id,
            location: item.location,
            available_qty: item.shadow_qty,
            item_qty: 0,
            measure_unit: item.item_measure_unit,
            item_description: "",
          });
          setTableData(data);
        });
      }
    } catch (error) {}
  };

  const SubmitHandler = async () => {
    const data = {
      dispose_id: disposeId,
      user_id: userId,
      date: formattedDate,
      branch_id: branchId,
      items: tableData,
    };
    console.log(data);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/dispose/add`,
        data
      );
      console.log(res.data);
      if (res.status === 200 || res.status === 201) {
        alert("Dispose Added Successfully");
        setDisposeId(IdGenerate("DISPOSE"));
        setTableData([]);
        setItems([]);
        setResultShow(false);
      }
    } catch (error) {
      if (error.response.status === 409) {
        alert("Already Exist");
      } else if (error.response.status === 400) {
        alert("Dispose Not Added");
      } else if (error.response.status === 500) {
        alert("Internal Server Error");
      } else if (error.response.status === 404) {
        alert("Dispose Not Found");
      } else if (error.response.status === 403) {
        alert("Forbidden");
      } else if (error.response.status === 401) {
        alert("Unauthorized");
      }
    }
  };

  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      //   borderColor: state.isFocused ? 'grey' : 'red',
      //   border:state.isFocused ? 'none' : 'none',
      //   borderColor:'black'
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };
  return (
    <div className="p-10 bg-background">
      <p className="header-1">Inventory Dispose</p>

      <div className="w-full flex justify-start items-center mt-10 ">
        <div className="w-[670px] h-[82px] flex justify-between items-center gap-x-10 bg-[#F3F8FF] px-10 rounded-[46px] border border-[#00000036]">
          <p className="label-1">Request Id :</p>
          <AsyncSelect
            className="w-80"
            styles={customStyles}
            loadOptions={SearchItems}
            onChange={SelectItemHandler}
            value={items.inventory_batch_id}
          />
        </div>
      </div>

      <p className="header-1">Dispose Item Details</p>

      <div className="flex flex-col justify-start  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
        <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
          <p className="w-10 p1 text-[#656565] text-center">#</p>
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Available Qty
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Quantity</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">unit</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Location</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Remarks</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Action</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
          {tableData.length > 0
            ? tableData.map((item, index) => (
                <div key={index} className="w-full  flex justify-around ">
                  <div className="w-10 flex items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {index + 1}
                    </p>
                  </div>
                  <div className="w-[350px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.available_qty}
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      <input
                        className="w-full border border-[#797979] rounded-[40px]"
                        value={item.item_qty}
                        onChange={(e) => {
                          let temp = [...tableData];
                          temp[index].item_qty = e.target.value;
                          setItems(temp);
                        }}
                      />
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.measure_unit}
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.location}
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      <textarea
                        className="w-full border border-[#797979] rounded-[40px] h-[60px]"
                        type="text"
                        value={item.item_description}
                        onChange={(e) => {
                          let temp = [...items];
                          temp[index].item_description = e.target.value;
                          setItems(temp);
                        }}
                      />
                    </p>
                  </div>

                  <div className="w-[200px] flex justify-center items-center">
                    {hasPermission(1240) ? (
                      <button
                        className="w-[full] h-[45px] flex items-center justify-center bg-[#912020] border rounded-[23px] px-4"
                        onClick={() => {
                          let temp = [...tableData];
                          temp.splice(index, 1);
                          setItems(temp);
                        }}
                      >
                        <p className="p2 text-white">delete</p>
                      </button>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      <div className="w-full flex justify-end items-center gap-x-10 my-10">
        {hasPermission(1241) && (
          <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4">
            <p className="p2 text-white" onClick={SubmitHandler}>
              Submit
            </p>
          </button>
        )}
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#912020] border rounded-[23px] px-4">
          <p className="p2 text-white">Cancel</p>
        </button>
      </div>
    </div>
  );
}
