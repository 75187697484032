import React, { useEffect, useState } from 'react';
import './addCustomer.css';
import IdGenerate from '../../../utils/id_generate';
import axios from 'axios';
import Alert from '../../../components/alert';

export default function AddCustomer(props) {
    const [alert, setAlert] = useState({
        status: "",
        alert: "",
        show: false,
    
      });
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId,setUserId] = useState('USER-000000');

    const [id,setId] = useState(IdGenerate('CUSTOMER'));
    const [name,setName] = useState('');
    const [nic,setNic] = useState('');
    const [address,setAddress] = useState('');
    const [contact,setContact] = useState('');
    const [email,setEmail] = useState('');
    const [customerType,setCustomertype] = useState('');
    const [regNo,setRegNo] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [creditLimit,setCreditLimit] = useState('') 

    const [nameStatus,setNameStatus] = useState(false);
    const [contactStatus,setContactStatus] = useState(false);
    const [emailStatus,setEmailStatus] = useState(false);
    const [customerTypeStatus,setCustomerTypeStatus] = useState(false);
    const [regNoStatus,setRegNoStatus] = useState(false);
    const [paymentMethodStatus,setPaymentMethodStatus] = useState(false);
    const [creditLimitStatus,setCreditLimitStatus] = useState(false);





    const [regNoView,setRegNoView] = useState(false);
    useEffect(()=>{
        if(customerType === 'doctor'){
            setRegNoView(true);
        }else{
            setRegNoView(false);
        }
    },[
        customerType
    ])



    //discount
    const [discount,setDiscount] = useState([{
        from:0,
        to:0,
        rate:0
    }]);

    const AddHandler = async ()=>{


        if(name === ''){
            setNameStatus(true)
        }else{
            setNameStatus(false)
        }
        if(contact === ''){
            setContactStatus(true)
        }else{
            setContactStatus(false)
        }
        if(email === ''){
            setEmailStatus(true)
        }else{
            setEmailStatus(false)
        }
        if(customerType === ''){
            setCustomerTypeStatus(true)
        }else{
            setCustomerTypeStatus(false)
        }
        if(regNo === '' && customerType === 'doctor'){
            setRegNoStatus(true)
        }else if(regNo === '' && customerType === 'agent'){
            setRegNoStatus(false)
        }else{
            setRegNoStatus(false)
        }

        if(paymentMethod === ''){
            setPaymentMethodStatus(true)
        }else{
            setPaymentMethodStatus(false)
        }
        if(paymentMethod === 'credit' && creditLimit === ''){
            setCreditLimitStatus(true)
        }else{
            setCreditLimitStatus(false)
        }

        

            const data = {
                customer_id:id,
                customer_name:name,
                customer_nic:nic,
                customer_address:address,
                customer_contact_no:contact,
                customer_email:email,
                customer_type:customerType,
                reg_no:regNo,
                customer_update_user_id:userId,
                customer_update_date:formattedDate,
                payment_method:paymentMethod,
                credit_limit:creditLimit,
                category:'registered',
                discount:discount,
            }

                if(nameStatus || contactStatus || emailStatus || customerTypeStatus  || regNoStatus || paymentMethodStatus){
           
                    setAlert({
                        status:"error",
                        alert:"Please fill the required fields",
                        show:true
                      })
                }else{

                    try{
                        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/add`, data)
                // console.log(res.data);
                if(res.status === 201 || 200){
                  
                    setAlert({
                        status:"success",
                        alert:"Customer added successfully",
                        show:true
                      })
                    setId(IdGenerate('CUSTOMER'));
                    setName('');
                    setNic('');
                    setAddress('');
                    setContact('');
                    setEmail('');
                    setCustomertype('');
                    setRegNo('');
                    setPaymentMethod('');
                    setDiscount([{
                        from:0,
                        to:0,
                        rate:0}])


               
                }
                    }catch (error) {
                        console.log(error);
                        setAlert({
                            status:"error",
                            alert:"Error adding customer",
                            show:true
                          })
                    }
                        

                    
                }
                
                


    
    }

    const CancelHandler = ()=>{

        setId(IdGenerate('CUSTOMER'));
        setName('');
        setNic('');
        setAddress('');
        setContact('');
        setEmail('');
        setCustomertype('');
        setRegNo('');
        setPaymentMethod('');
        setDiscount([{
            from:0,
            to:0,
            rate:0}])
    }

  return (
    <div className='w-full h-full flex flex-col items-center justify-center px-20 bg-background '>
        <Alert 
      show={alert.show} 
      alert={alert.alert} 
      status={alert.status} 
      ok={()=>{setAlert({
                status: "",
                alert: "",
                show: false,
        })}}
        />
        <div className='w-full flex justify-start'>
            <p className="header-1 mt-5">Add Customer</p>
        </div>

        <div className="w-full bg-[#F3F8FF] px-20 py-5 rounded-[46px] flex flex-col items-center justify-center mx-16 mt-5">
            <p className="header-1">Enter Customer Details</p>
            <div className='flex items-center justify-between flex-wrap w-full gap-y-10 py-10'>
                {/* <div className="flex items-center justify-between w-[600px]">
                    <p className='label-1'>Customer ID :</p>
                    <input className='w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold' placeholder='Enter User ID'
                    type="text" value={id} onChange={(e)=>setId(e.target.value)} disabled
                    />
                </div> */}
                <div className="flex items-center justify-between w-[600px]">
                    <p className='label-1'>Customer Name :</p>
                    <input className={nameStatus ?                    
                    'w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold'
                    :'w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold'} placeholder='Enter Customer Name *'
                    type="text" value={name} onChange={(e)=>setName(e.target.value)}
                    />
                </div>
                <div className="flex items-center justify-between w-[600px]">
                    <p className='label-1'>NIC :</p>
                    <input className='w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold ' placeholder='XXXXXXXXXXXX'
                    type="text" value={nic} onChange={(e)=>setNic(e.target.value)}
                    />
                </div>
                <div className="flex items-center justify-between w-[600px]">
                    <p className='label-1'>Address :</p>
                    <input className='w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold ' placeholder='Enter Address'
                    type="text" value={address} onChange={(e)=>setAddress(e.target.value)}/>
                </div>
                <div className="flex items-center justify-between w-[600px]">
                    <p className='label-1'>Contact number :</p>
                    <input className={contactStatus ? 'w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold '
                    :'w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold '} placeholder='Enter Contact Number *'
                    type="number" value={contact} onChange={(e)=>setContact(e.target.value)}/>
                </div>
                <div className="flex items-center justify-between w-[600px]">
                    <p className='label-1'>E-mail Address :</p>
                    <input className={emailStatus?'w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold'
                    :'w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold'} placeholder='Enter Email Address *'
                    type="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                </div>
                <div className="flex items-center justify-between w-[600px]">
                    <p className='label-1'>Customer type :</p>
                    <select className={customerTypeStatus ? 'w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold ':
                    'w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold '} value={customerType} onChange={(e)=>setCustomertype(e.target.value)}>
                        <option>select</option>
                        <option value={'doctor'}>doctor</option>
                        <option value={'agent'}>agent</option> 
                    </select>
                </div>
                <div className="flex items-center justify-between w-[600px]">
                    <p className='label-1'>Reg. No. :</p>
                    <input className={regNoStatus ? 'w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold'
                    :'w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold'} placeholder='Enter Registration No'
                    type="text" value={regNo} onChange={(e)=>setRegNo(e.target.value)} disabled={!regNoView} />
                </div>
            </div>

            <div className='flex items-center justify-between w-full py-10'>
                <div className="flex items-center justify-between w-[600px]">
                    <p className='label-1'>Payment Method :</p>
                    <select className={paymentMethodStatus ? 'w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold ':
                    'w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold '} value={paymentMethod} onChange={(e)=>setPaymentMethod(e.target.value)}>
                        <option>select</option>
                        <option value={'cash'}>cash</option>
                        <option value={'credit'}>credit</option> 
                    </select>
                </div>
                <div className={paymentMethod === 'credit' ? "flex items-center justify-between w-[600px]" : "hidden"}>
                    <p className='label-1'>Credit Limit :</p>
                    <input className={creditLimitStatus ? 'w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold ':
                    'w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold '} placeholder='Enter Credit Limit'
                    value={creditLimit} onChange={(e)=>setCreditLimit(e.target.value)} />
                </div>
            </div>
            
        </div>

        <div className="w-full bg-[#F3F8FF] px-20 py-5 rounded-[46px] flex flex-col items-center justify-center mx-16 mt-5">
            <p className="header-1 mb-10">Enter Discount Details</p>

            {discount.length > 0 ? discount.map((data,index)=>{
                return(<div key={index} className='w-full flex justify-between items-center my-2'>
                <div className='flex flex-col gap-y-3'>
                    <p className='label-1'>From :</p>
                    <input className='w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold'
                    value={data.from} type='number' onChange={(e)=>{
                        const Data = [...discount]
                        Data[index].from = e.target.value;
                        setDiscount(Data);
                    
                    }}/>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <p className='label-1'>To :</p>
                    <input className='w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold'
                    value={data.to} type='number' onChange={(e)=>{
                        const Data = [...discount]
                        Data[index].to = e.target.value;
                        setDiscount(Data);

                    }}/>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <p className='label-1'>Discount Rate : </p>
                    <input className='w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold'
                    value={data.rate} type='number' onChange={(e)=>{
                        const Data = [...discount]
                        Data[index].rate = e.target.value;
                        setDiscount(Data);
                    }}/>
                </div>
                <div className='pt-12'>
                    <button className='w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl'
                    onClick={()=>{
                        const Data = [...discount]
                        Data.splice(index,1)
                        setDiscount(Data);
                    }}
                    >Delete</button>
                </div>
            </div>)
            }) : null}
            

            <div className='w-full flex justify-start my-9'>
                <button className='w-[118px] h-[45px] flex items-center justify-center bg-[#43aa43] border rounded-[23px] px-4 text-white font-bold text-xl'
                onClick={()=>{
                    const Data = [...discount]
                    Data.push({
                        from:Data.length > 0 ? Data[Data.length - 1].to : 0 ,
                        to:0,
                        rate:0
                    })
                    setDiscount(Data);
                }}
                >add</button>
            </div>
        </div>
        <div className='flex w-full items-center justify-end gap-x-10 my-10'>
            <button className='w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl' onClick={CancelHandler}>Cancel</button>
            <button className='w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl' onClick={AddHandler}>Add</button>
        </div>
        
        
        
    </div>
  )
}
