 

import React, { useEffect, useState } from "react";
import "./posDashboard.css";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

import Earth from "../../../Assets/icons/Sphere-Green-Glossy.png";

import Card1 from "../../../components/card 1/card1";
import BillPreview1 from "../../../components/bill preview 1/billPreview1";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";

export default function PosDashboard() {
  const { hasPermission } = usePermissions();

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string

  const [billPreviewShow, setBillPreviewShow] = useState(false);
  const [billId, setBillId] = useState("");
  const [brachId, setBranchId] = useState("BRANCH-0001");
  const [billDate, setBillDate] = useState(formattedDate.split("T")[0]);

  //today
  const [todayTotal, setTodayTotal] = useState(0);
  const [todayBill, setTodayBill] = useState(0);
  const [todayBills, setTodayBills] = useState([]);

  //get bills
  const ToDaySale = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/pos/billsAccToDate/Branch/PosType/${billDate}/${brachId}/BRANCH-POS`
      );
      // console.log(res.data)
      setTodayBills(res.data);
      let total = 0;
      let bill = 0;
      if (res.data.length > 0) {
        res.data.forEach((element) => {
          total = total + parseInt(element.pos_net_total);
          bill++;
          // console.log(total)
        });
        setTodayTotal(total);
        setTodayBill(bill);
      }
    } catch (error) {}
  };
  useEffect(() => {
    ToDaySale();
  }, [billDate]);

  const [billItems, setBillItems] = useState([]);
  const GetBillItems = async () => {
    try {
      const promises = todayBills.map(async (element) => {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/pos/getPos/${element.pos_id}`
        );
        console.log(res.data);
        return res.data;
      });

      const billItemsData = await Promise.all(promises);

      // Flatten the array of arrays into a single array
      const flattenedBillItems = billItemsData.flat();

      // Update billItems state after all promises are resolved
      setBillItems(flattenedBillItems);
    } catch (error) {
      console.error("Error fetching bill items:", error);
    }
  };

  useEffect(() => {
    GetBillItems();
  }, [todayBills]);

  const [totalBilItems, setTotalBillItems] = useState();

  function calculateTotalQtyPerItem(items) {
    const itemTotals = {};
    items.forEach((item) => {
      // billItems
      const itemName = item.item_name;
      const itemQty = parseInt(item.pos_item_qty);

      // console.log('bill items',billItems)
      if (itemTotals[itemName]) {
        itemTotals[itemName] += itemQty;
      } else {
        itemTotals[itemName] = itemQty;
        // console.log(itemQty)
      }
      console.log(itemTotals);
    });
    // Convert itemTotals object into an array of objects
    const resultArray = Object.keys(itemTotals).map((key) => ({
      name: key,
      qty: itemTotals[key],
    }));
    setTotalBillItems(resultArray);
    // console.log(resultArray);
  }

  useEffect(() => {
    // console.log(billItems)
    calculateTotalQtyPerItem(billItems);
  }, [billItems]);

  //print

  // const Print = () => {

  // }

  return (
    <div className="p-10 bg-background">
      <p className="header-1">Dashboard</p>
      <div className="w-full flex flex-wrap justify-between my-10 px-5">
        {hasPermission(1090) && (
          <Card1
            title1="Sales"
            title2=""
            subTitle="(Today)"
            info2={todayTotal}
            value={todayTotal}
            img={Earth}
          />
        )}
        {hasPermission(1091) && (
          <Card1
            title1="Total Sales"
            title2=""
            subTitle="(Today)"
            info2={todayTotal}
            value={todayBill}
            img={Earth}
          />
        )}{" "}
        {hasPermission(1092) && (
          <Card1
            title1="Total Sold"
            title2="Items"
            subTitle="(Today)"
            info2={todayTotal}
            value={billItems.length}
            img={Earth}
          />
        )}
      </div>
      <div className="w-full flex justify-end items-center gap-5">
        <p className="label-1">Date </p>
        <p className="label-1">:</p>
        <input
          className="w-[259px] h-[53px] rounded-[46px] border px-5"
          type="date"
          value={billDate}
          onChange={(e) => {
            setBillDate(e.target.value);
          }}
        />
      </div>

      <div className="w-full h-[240px] bg-[#F3F8FF] rounded-[46px] flex flex-col justify-start my-10">
        <div className="w-full h-[72px] rounded-[46px] bg-[#BFD8AE] flex justify-around items-center px-5 pr-[35px]">
          <p className="font-bold text-[24px] text-[#656565] text-center w-3">
            #
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[350px]">
            Bill Id
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
            Date
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[100px]">
            Time
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[100px]">
            Total
          </p>
        </div>
        <div className="w-ful h-[130px] flex flex-col justify-around items-center px-5 overflow-y-scroll ">
          {todayBills.length > 0
            ? todayBills.map((bill, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setBillId(bill.pos_id);
                      setBillPreviewShow(true);
                    }}
                    className="w-full  flex justify-around items-center  "
                  >
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-3">
                      {index + 1}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-[350px]">
                      {bill.pos_id}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
                      {bill.pos_date.split("T")[0]}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-[100px]">
                      {bill.pos_date.split("T")[1]}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-[100px]">
                      {bill.pos_net_total}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </div>

      <div className="w-full h-[240px] bg-[#F3F8FF] rounded-[46px] flex flex-col justify-start my-10">
        <div className="w-full h-[72px] rounded-[46px] bg-[#BFD8AE] flex justify-around items-center px-5 pr-[35px]">
          <p className="font-bold text-[24px] text-[#656565] text-center w-3">
            #
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[350px]">
            Item Name
          </p>
          <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
            Sold Qty
          </p>
        </div>
        <div className="w-ful h-[130px] flex flex-col justify-around items-center px-5 overflow-y-scroll ">
          {totalBilItems && totalBilItems.length > 0
            ? totalBilItems.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full  flex justify-around items-center  "
                  >
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-3">
                      {index + 1}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-[350px]">
                      {item.name}
                    </p>
                    <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
                      {item.qty}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </div>

      <div className="w-full pr-[90px] absolute top-0  ml-[-50px]">
        {billPreviewShow && (
          <BillPreview1
            id={billId}
            CancelHandler={() => {
              setBillPreviewShow(false);
            }}
          />
        )}
      </div>
    </div>
  );
}
