 

import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import "./updateItem.css";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";

import Arrow from "../../../Assets/icons/down-arrow.png";

export default function UpdateItem() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");

  const [resultsShow, setResultsShow] = useState(false);

  const [results, setResults] = useState([]);
  const ItemSearchHandler = async (e) => {
    if (e.target.value !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/item/search/name-status/ACTIVE/${e.target.value}`
        );
        console.log(res.data);
        setResults(res.data);
      } catch (error) {}
    }
  };

  const [category, setCategory] = useState([]);
  const GetCategoryData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/itemCategory/status/ACTIVE`
      );
      console.log(res.data);
      setCategory(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    GetCategoryData();
  }, []);

  const [data, setData] = useState({
    item_category_id: "",
    item_category_name: "",
    item_description: "",
    item_id: "",
    item_measure_unit: "",
    item_name: "",
    item_status: "ACTIVE",
    item_unit_selling_price: "",
    item_update_date: "",
    item_update_user_id: "",
  });
  const GetItemData = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/item/item/${id}`
      );
      console.log(res.data);
      setData(res.data[0]);
    } catch (error) {}
  };

  const UpdateItemHandler = async () => {
    if (data.item_id !== "") {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/item/update/${data.item_id}`,
          {
            data: data,
            user_id: "USER-00001",
            update_date: formattedDate,
          }
        );
        console.log(res.data);
        if (res.status === 200) {
          alert("Item Updated Successfully");
          window.location.reload();
        }
      } catch (error) {
        if (error.response.status === 500) {
          alert("Internal Server Error");
        } else if (error.response.status === 400) {
          alert("fill all fields");
        } else if (error.response.status === 409) {
          alert("Item Already Exists");
        } else {
          alert("Something Went Wrong");
        }
      }
    }
  };

  const DeleteItemHandler = async () => {
    if (data.item_id !== "") {
      try {
        const res = await axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/item/delete/${data.item_id}`
        );
        console.log(res.data);
        if (res.status === 200) {
          alert("Item Deleted Successfully");
          window.location.reload();
        }
      } catch (error) {
        if (error.response.status === 500) {
          alert("Internal Server Error");
        } else if (error.response.status === 400) {
          alert("fill all fields");
        } else if (error.response.status === 409) {
          alert("Item Already Exists");
        } else {
          alert("Something Went Wrong");
        }
      }
    }
  };

  const CancelItemHandler = () => {};
  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      borderColor: state.isFocused ? "grey" : "red",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  return (
    <div className=" bg-background h-screen overflow-y-auto">
      <p className="header-1"> Update Item</p>
      <div className="flex gap-10 items-center py-5 px-10 w-[800px] mt-10 bg-[#F3F8FF] rounded-[46px] border border-[#00000036]">
        <p className="label-1"> Search Item Category</p>
        <p className="label-1">:</p>
        {hasPermission(1670) && (
          <AsyncSelect
            className="w-80"
            styles={customStyles}
            cacheOptions
            loadOptions={ItemSearchHandler}
            // defaultOptions
            onChange={GetItemData}
            value={results}
          />
        )}
      </div>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Update Item</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Id</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              disabled
              value={data.item_id}
              onChange={(e) => {
                setData({ ...data, item_id: e.target.value });
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={data.item_name}
              onChange={(e) => {
                setData({ ...data, item_name: e.target.value });
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Category</label>
            <label className="label-1">:</label>
            <select
              className="input-1 border border-[#00000036]"
              value={
                data.item_category_name === ""
                  ? "item category"
                  : data.item_category_name
              }
              onChange={(e) => {
                setData({
                  ...data,
                  item_category_id: e.target.value.split("/")[1],
                  item_category_name: e.target.value.split("/")[0],
                });
              }}
            >
              <option
                value={
                  data.item_category_name === "" ? "" : data.item_category_name
                }
              >
                {data.item_category_name === ""
                  ? "item category"
                  : data.item_category_name}
              </option>

              {category.length > 0 ? (
                category.map((category, index) => {
                  return (
                    <option
                      key={index}
                      value={`${category.item_category_name}/${category.item_category_id}`}
                    >
                      {category.item_category_name}
                    </option>
                  );
                })
              ) : (
                <option>select category</option>
              )}
            </select>
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Measure Unit</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={data.item_measure_unit}
              onChange={(e) => {
                setData({ ...data, item_measure_unit: e.target.value });
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Selling Price</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={data.item_unit_selling_price}
              onChange={(e) => {
                setData({ ...data, item_unit_selling_price: e.target.value });
              }}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Item Description</label>
            <label className="label-1">:</label>
            <textarea
              className="form-input border border-[#00000036]"
              value={data.item_description}
              onChange={(e) => {
                setData({ ...data, item_description: e.target.value });
              }}
            />
          </div>
        </div>
      </div>
      <div className=" flex items-center justify-end gap-x-6 mb-10 px-[150px]">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
          onClick={CancelItemHandler}
        >
          <p className="p2 text-white">Cancel</p>
        </button>
        {hasPermission(1690) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
            onClick={DeleteItemHandler}
          >
            <p className="p2 text-white">Delete</p>
          </button>
        )}
        {hasPermission(1680) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
            onClick={UpdateItemHandler}
          >
            <p className="p2 text-white">Update</p>
          </button>
        )}
      </div>
    </div>
  );
}
