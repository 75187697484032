import React, { useState } from 'react';
import './addItemcategory.css';
import axios from 'axios';

import IdGenerate from '../../../utils/id_generate';

export default function AddItemCategory() {
  const currentDate = new Date(); // Get the current date and time
    const formattedDate = currentDate.toISOString(); // Format the date to ISO string
    const [userId,setUserId] = useState('USER-000000');

  const [data, setData] = useState({
    categoryId: IdGenerate('ITEM-CATEGORY'),
    categoryName: '',
    categoryDescription: '',
    categoryStatus:'ACTIVE'
  })
  const ChangeHandler =(e) => {
    const Data = {...data}
    Data[e.target.id] = e.target.value;
    setData(Data)
  }

  const SubmitHandler =async () => {
    if(data.categoryName === '' || data.categoryDescription === '' || data.categoryStatus === ''){
      alert('Please fill all the fields')
    }else{
      try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/itemCategory/add`, {
          item_category_id:data.categoryId,
          item_category_name:data.categoryName,
          item_category_update_date:formattedDate,
          item_category_update_user_id:userId,
          item_category_description:data.categoryDescription,
          item_category_status:data.categoryStatus
        })  
        if(res.status === 200){
          alert('Item Category Added Successfully');
          // setData({
          //   categoryId: IdGenerate('ITEM-CATEGORY'),
          //   categoryName: '',
          //   categoryDescription: '',
          //   categoryStatus:''
          // })
          window.location.reload()
        }
      } catch (error) {
        if(error.response.status === 500){
          alert('Internal Server Error')
        }else if(error.response.status === 400){
          alert('fill all fields')
        }else if(error.response.status === 409){
          alert('Item Category Already Exists')
        
        }else{
          alert('Something Went Wrong')
        
        }

        
      }
    }
  }
  return (
    <div className='p-10 bg-background'>
      <p className='header-1'> Add Item Category</p>
      <div className='w-full flex items-center justify-center'>
        <div className='bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center'>
          <p className='header-1 text-center'>Enter Item Category Details</p>

          <div className='w-[600px] flex items-center justify-between my-10'>
            <p className="label-1">Category Id</p>
            <p className="label-1">:</p>
            <input className='input-1 border border-[#00000036]' id='categoryId' onChange={(e)=>ChangeHandler(e)} value={data.categoryId}/>
          </div>
          <div className='w-[600px] flex items-center justify-between my-10'>
            <p className="label-1">Category Name</p>
            <p className="label-1">:</p>
            <input className='input-1 border border-[#00000036]'  id='categoryName' value={data.categoryName} onChange={(e)=>ChangeHandler(e)} />
          </div>
          <div className='w-[600px] flex items-center justify-between my-10'>
            <p className="label-1">Category Description</p>
            <p className="label-1">:</p>
            <input className='input-1 border border-[#00000036]' id='categoryDescription' onChange={(e)=>ChangeHandler(e)} value={data.categoryDescription}/>
          </div>
        </div>
      </div>
      <div className=' flex items-center justify-end gap-x-6 mb-10 px-[360px]'>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4" >
          <p  className='p2 text-white' >Cancel</p>
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4" onClick={()=>SubmitHandler()}>
          <p  className='p2 text-white' >Add</p>
        </button>
      </div>
    </div>
  )
}
