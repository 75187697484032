 

import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import arrow from "../../../Assets/icons/down-arrow.png";
import "./updateBranches.css";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";

export default function UpdateBranches() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");

  const [resultShow, setResultShow] = useState(false);

  const [result, setResult] = useState([]);
  const SearchBranch = async (e) => {
    if (e !== "") {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/branch/search/BranchName/Status/${e}/ACTIVE`
        );
        console.log(res.data);
        const options = res.data.map((branch) => ({
          value: branch.branch_id,
          label: branch.branch_name,
        }));
        setResult(options);
        return options;
      } catch (error) {}
    }
  };

  const [branch, setBranch] = useState({
    branch_id: "",
    branch_name: "",
    branch_address: "",
    branch_description: "",
    branch_update_date: "",
  });
  const GetBranch = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/branch/branch/${id.value}`
      );
      // console.log(res.data[0]);
      setBranch(res.data[0]);
    } catch (error) {}
  };

  const UpdateHandler = async () => {
    const data = {
      branch_id: branch.branch_id,
      branch_name: branch.branch_name,
      branch_address: branch.branch_address,
      branch_description: branch.branch_description,
      branch_update_date: formattedDate,
      branch_update_user_id: userId,
    };
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/branch/update/Branch/${data.branch_id}`,
        data
      );
      // console.log(res.data);
    } catch (error) {
      if (error.response.status === 409) {
        alert("Branch Already Exist");
      } else if (error.response.status === 400) {
        alert("Branch Not Updated");
      } else if (error.response.status === 500) {
        alert("Internal Server Error");
      } else if (error.response.status === 404) {
        alert("Branch Not Found");
      } else if (error.response.status === 403) {
        alert("Forbidden");
      } else if (error.response.status === 401) {
        alert("Unauthorized");
      }
    }
  };

  const DeleteHandler = async () => {
    alert("are you sure!");
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/branch/delete/Branch/${branch.branch_id}`
      );
      // console.log(res.data);
    } catch (error) {
      if (error.response.status === 409) {
        alert("Branch Already Exist");
      } else if (error.response.status === 400) {
        alert("Branch Not Deleted");
      } else if (error.response.status === 500) {
        alert("Internal Server Error");
      } else if (error.response.status === 404) {
        alert("Branch Not Found");
      } else if (error.response.status === 403) {
        alert("Forbidden");
      } else if (error.response.status === 401) {
        alert("Unauthorized");
      }
    }
  };

  const CancelHandler = async () => {
    setBranch({
      branch_id: "",
      branch_name: "",
      branch_address: "",
      branch_description: "",
      branch_update_date: "",
    });
  };
  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      borderColor: state.isFocused ? "grey" : "red",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };
  return (
    <div className="p-10 bg-background">
      <p className="header-1 mt-5"> Update Branches</p>

      <div className="flex items-center justify-around w-[600px] my-10">
        <p className="label-1">Search Branch </p>
        <p className="label-1">: </p>
        <AsyncSelect
          className="w-80"
          styles={customStyles}
          cacheOptions
          loadOptions={SearchBranch}
          // defaultOptions
          onChange={GetBranch}
          value={result}
        />
      </div>

      <div className="w-full bg-[#F3F8FF] px-20 py-5 rounded-[46px] flex flex-col items-center justify-center  mt-5">
        <p className="header-1">Update Details</p>
        <div className="flex items-center justify-between flex-wrap w-full gap-y-10 py-10">
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Branch ID :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              value={branch.branch_id}
              onChange={() => {}}
              disabled
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Branch Name :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              value={branch.branch_name}
              onChange={(e) => {
                setBranch({ ...branch, branch_name: e.target.value });
              }}
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Branch Address :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              value={branch.branch_address}
              onChange={(e) => {
                setBranch({ ...branch, branch_address: e.target.value });
              }}
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Branch Description :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              value={branch.branch_description}
              onChange={(e) => {
                setBranch({ ...branch, branch_description: e.target.value });
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={CancelHandler}
        >
          Cancel
        </button>
        {hasPermission(1510) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl"
            onClick={DeleteHandler}
          >
            Delete
          </button>
        )}{" "}
        {hasPermission(1500) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl"
            onClick={UpdateHandler}
          >
            UPDATE
          </button>
        )}
      </div>
    </div>
  );
}
