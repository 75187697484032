import React, { useState } from "react";
import { useLocation } from "react-router-dom";

function CreateProduction(props) {
  const [itemSections, setItemSections] = useState(1);
  const [stages, setStages] = useState([]); // Renamed state variables
  const location = useLocation();
  const { productionId } = location.state;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;

  console.log("production ID: ", productionId);
  const handleSubmit = (event) => {
    event.preventDefault();
    const stages = [];

    for (let i = 0; i < itemSections; i++) {
      const stageNameField = event.target[`stage_name_${i}`];
      if (!stageNameField) {
        console.error(`Stage name field ${i} not found`);
        continue;
      }
      const stageName = stageNameField.value;

      const supervisorIdField = event.target[`supervisor_id_${i}`];
      if (!supervisorIdField) {
        console.error(`Supervisor ID field ${i} not found`);
        continue;
      }
      const supervisorId = supervisorIdField.value;

      const estimateTimeField = event.target[`estimate_time_${i}`];
      if (!estimateTimeField) {
        console.error(`Estimate time field ${i} not found`);
        continue;
      }
      const estimateTime = estimateTimeField.value;

      const supervisorNoteField = event.target[`supervisor_note_${i}`];
      if (!supervisorNoteField) {
        console.error(`Supervisor note field ${i} not found`);
        continue;
      }
      const supervisorNote = supervisorNoteField.value;

      const stage = {
        stage_name: stageName,
        production_id: productionId,
        stage_create_by: "EMP-004",
        stage_estimate_time: estimateTime,
        stage_supervisor_id: supervisorId,
        stage_supervisor_note: supervisorNote,
      };

      stages.push(stage);
    }

    fetch(`${REACT_APP_API_ENDPOINT}/v1/production/stage/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(stages),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Stages added successfully");
          // alert("Stages added successfully");
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 4000);
        } else {
          console.error("Failed to add stages");
          alert("Failed to add stages");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error occurred. Please try again.");
      });
  };

  const handleAddItemSection = () => {
    setItemSections((prev) => prev + 1);
  };

  const handleRemoveItemSection = (indexToRemove) => {
    setItemSections((prev) => prev - 1);
    setStages((prevStages) =>
      prevStages.filter((_, index) => index !== indexToRemove)
    );
  };

  const renderItemSections = () => {
    const sections = [];
    for (let i = 0; i < itemSections; i++) {
      sections.push(
        <div key={i} className="item choose section ">
          <div
            className="box-border h-[auto] w-[900px] p-4 border-2"
            style={{ marginTop: i > 0 ? "40px" : "0" }}
          >
            <div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => handleRemoveItemSection(i)}
                  className="flex items-center justify-center w-8 h-8 rounded-full bg-[#D4E7C5] text-black hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div>
                <label
                  htmlFor={`stage_name_${i}`}
                  className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                >
                  Stage {i + 1} Name
                </label>
                <input
                  type="text"
                  name={`stage_name_${i}`}
                  id={`stage_name_${i}`}
                  className="w-[400px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div>
                <label
                  htmlFor={`supervisor_id_${i}`}
                  className="block text-sm font-medium leading-6 text-gray-900 mr-4 mt-5"
                >
                  Supervisor
                </label>
                <div className="mt-2 flex flex-row items-center">
                  <select
                    id={`supervisor_id_${i}`}
                    name={`supervisor_id_${i}`}
                    className="pl-5 px-4 block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value="EMP-005">Supervisor 1</option>
                    <option value="EMP-006">Supervisor 2</option>
                    <option value="EMP-007">Supervisor 3</option>
                  </select>
                </div>
              </div>
              <div>
                <label
                  htmlFor={`estimate_time_${i}`}
                  className="block text-sm font-medium leading-6 text-gray-900 mr-4 mt-5"
                >
                  Estimate Time
                </label>
                <input
                  type="text"
                  name={`estimate_time_${i}`}
                  id={`estimate_time_${i}`}
                  className="w-[65%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div>
                <label
                  htmlFor={`supervisor_note_${i}`}
                  className="block text-sm font-medium leading-6 text-gray-900 mr-4 mt-5"
                >
                  Supervisor Note
                </label>
                <textarea
                  id={`supervisor_note_${i}`}
                  name={`supervisor_note_${i}`}
                  rows={3}
                  className="block w-[400px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 pl-5 px-4 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={""}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return sections;
  };

  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl">
        Add Stages
      </p>
      <div className="box-border w-full p-4 bg-white">
        <div>
          <h2 className="text-2xl font-semibold mb-4 rounded-lg ml-[30px]">
            Production ID - {productionId}
          </h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center">
            <div className="space-y-12">
              <div className="ml-8">
                <br />
                {renderItemSections()}{" "}
                <div className="flex items-center gap-3">
                  <div className="mt-8">
                    <button
                      type="button"
                      onClick={handleAddItemSection}
                      className="rounded-md mb-[90px] bg-[#D4E7C5] px-3 py-2 text-sm text-Black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Add
                    </button>
                  </div>
                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="mt-[-25px] mb-[70px] flex items-center gap-x-6">
                      <button
                        type="submit"
                        className="rounded-md bg-[#D4E7C5] px-3 py-2 text-sm text-Black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Submit
                      </button>
                      {showSuccessMessage && (
                        <div className="text-green-600 text-sm mt-2">
                          Stage Added successfully!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateProduction;
