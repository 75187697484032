/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import logoImage from "../../Assets/icons/login-new.png";
import erpLogo from "../../Assets/icons/erpp.png";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Tick_Submit from "../../Assets//icons/Tick-Submit.png";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showLogInConfirmation, setShowLogInConfirmation] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleSubmit = (event) => {
    event.preventDefault();

    const requestBody = {
      username: email,
      password: password,
    };

    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/user/userLogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.user_token) {
          // Save user data in cookies
          Cookies.set("username", email);
          Cookies.set("user_token", data.user_token);
          Cookies.set("employee_no", data.employee_no);
          Cookies.set("employee_fullname", data.employee_fullname);
          Cookies.set("employee_name_initial", data.employee_name_initial);
          Cookies.set("employee_nic", data.employee_nic);
          Cookies.set("token", data.permissions.join("-"));

          setLoginError("");
          setShowLogInConfirmation(true);
          // Set a timeout to navigate to the dashboard after 5 seconds
          setTimeout(() => {
            setShowLogInConfirmation(false);
            navigate("/dashboard");
          }, 1000);
        } else {
          setLoginError("Invalid email or password. Please try again.");
          console.log("Invalid credentials");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <div className="flex gap-[100px] items-center bg-gray-100 overflow-hidden">
      <div className="mr-2">
        <img
          src={logoImage}
          alt="Logo"
          className="w-[550px] h-[850px] rounded-b-[179px] ml-20 mr-20"
        />
      </div>

      <div className="w-1/2 p-8">
        <div className="">
          <img src={erpLogo} alt="Logo" className="w-[200px] mb-5" />
        </div>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Employee Login
        </h2>
        <div className="mb-4">
          <p className="text-sm font-sans font-semibold">
            Login to access your Ayuruveda account
          </p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Email input */}
          <div className="flex flex-col w-fit static">
            <label
              htmlFor="email"
              className="text-md font-semibold relative top-2 ml-[7px] px-[3px] bg-gray-100 rounded-[20px] w-fit"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-black px-[10px] py-[11px] text-ml bg-white border-2 rounded-[54px] h-[56px] w-[512px] focus:outline-none placeholder:text-black/25"
            />
          </div>

          <div className="flex flex-col w-fit static">
            <label
              htmlFor="password"
              className="text-md font-semibold relative top-2 ml-[7px] px-[3px] bg-gray-100 rounded-[20px] w-fit z-10"
            >
              Password
            </label>
            <div className="relative">
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border-black px-[10px] py-[11px] text-ml bg-white border-2 rounded-[54px] h-[56px] w-[512px] focus:outline-none placeholder:text-black/25"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-[10px] focus:outline-none"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <FiEyeOff className="text-[#252C58] h-[20px] w-[20px]" />
                ) : (
                  <FiEye className="text-[#252C58] h-[20px] w-[20px]" />
                )}
              </button>
            </div>
          </div>
          <div className="mt-[20px] text-red-500">
            {loginError && <p>{loginError}</p>}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input type="checkbox" id="remember" className="mr-2" />
              <label htmlFor="remember" className="text-sm text-gray-600">
                Remember me
              </label>
            </div>
          </div>
          <div className="mt-[80px]">
            <button
              type="submit"
              className="w-[512px] bg-lime-500 text-[25px] text-white font-bold py-2 focus:outline-none rounded-[63px] mt-10"
            >
              Login
            </button>
          </div>
        </form>
      </div>
      {showLogInConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50 ">
          <div className="bg-white p-6 mt-10 rounded-[20px] w-[30%]">
            <p className="mb-4 font-semibold text-[20px]">Login Successful</p>
            <img
              className="w-[40px] h-[60px] ml-[70%] -mt-10 text-center"
              src={Tick_Submit}
              alt="submit-tick"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
