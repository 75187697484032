 

import React, { useState, useEffect } from "react";
import { MdOutlineNotStarted } from "react-icons/md";
import StartStage from "./StartStage"; // Import StartStage component
import usePermissions from "../../../components/permissions/permission";

function StageData({ productionId, onRowClick }) {
  const { hasPermission } = usePermissions();
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [showStartStage, setShowStartStage] = useState(false); // State to control StartStage visibility
  const [stageName, setStageName] = useState("");
  const [supervisorNote, setSupervisorNote] = useState("");
  const [estimateTime, setEstimateTime] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [loading, setLoading] = useState(true);
  const [stageData, setStageData] = useState([]);
  const [selectedProductionId, setSelectedProductionId] = useState(null);
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const itemsPerPage = 3; // Update items per page to 3
  const [selectedStageId, setSelectedStageId] = useState(null); // Store selected stage ID
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleStartStage = (stageId) => {
    setSelectedStageId(stageId); // Store selected stage ID
    setShowStartStage(true);
  };
  useEffect(() => {
    if (productionId) {
      fetchData(productionId);
    }
  }, [productionId]); // Fetch data when productionId changes
  console.log("productionId:", productionId);

  const fetchData = () => {
    if (productionId) {
      fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/stage/get?production_id=${productionId}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setStageData(data); // Update stageData state with fetched data
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  };
  const getRowColor = (status) => {
    switch (status) {
      case "COMPLETE":
        return "green";
      case "PENDING":
        return "yellow";
      case "REJECT":
        return "red";
      default:
        return ""; // default color if status is unknown
    }
  };
  // Calculate total pages based on number of items per page
  const totalPages = Math.ceil(stageData.length / itemsPerPage);

  // Calculate the start and end indexes for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's data
  const currentPageData = stageData.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleBackToTable = () => {
    setShowStartStage(false); // Show table when back button is clicked
  };

  // Function to handle row click
  const handleRowClick = (row) => {
    onRowClick(row.production_id); // Pass production_id to the parent component
  };
  // Function to format date and time
  function formatDateTime(dateTimeString) {
    if (!dateTimeString) {
      return ""; // Return empty string if dateTimeString is empty or null
    }

    const dateTime = new Date(dateTimeString);

    // Options for date and time formatting
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Format the date and time
    const formattedDateTime = dateTime.toLocaleDateString("en-US", options);
    return formattedDateTime;
  }
  return (
    <div className="overflow-y-auto h-[calc(100vh-80px)] overflow-x-hidden">
      {/* Added vertical scrolling */}
      <div className="box-border w-full p-4 bg-[#c6d2b05e] ml-9 mt-5 relative rounded-b-[60px]">
        {showStartStage ? (
          // Render StartStage component instead of table
          <StartStage
            onBackToTable={handleBackToTable}
            stageId={selectedStageId}
          />
        ) : (
          <>
            <div className="relative ml-5">
              <div className="absolute top-0 left-0 w-[150px] h-[150px] bg-[#99BC85] rounded-full"></div>
              <div className="text-right mr-[100px]">
                <h2 className="text-2xl font-semibold mb-4 rounded-lg ml-[100]">
                  Production ID - {productionId}
                </h2>
              </div>
              <div className="absolute top-3 left-3 w-[126px] h-[126px] bg-white rounded-full">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <p className="text-2xl font-semibold text-center">
                    {stageData.length} Stages
                  </p>{" "}
                </div>
              </div>
            </div>

            <div
              className="overflow-x-auto mt-[130px] overflow-y-auto"
              style={{ width: "100%" }}
            >
              <div className="ml-[250]"></div>
              <table className=" border-collapse border border-gray-200 rounded-lg ml-6">
                <thead>
                  <tr className="bg-[#99BC85]">
                    <th className="border border-gray-200 px-10 py-4 w-[150px] text-center">
                      Stage ID
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[150px] text-center">
                      Stage Name
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[150px] text-center">
                      Progress
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[150px] text-center">
                      Estimated Time
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[150px] text-center">
                      Exceed Time
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[150px] text-center">
                      Start time
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[150px] text-center">
                      End time
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[150px] text-center">
                      Start Stage
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentPageData.map((row, index) => (
                    <tr
                      key={index}
                      className={`border border-gray-200 px-4 py-2 ${getRowColor(
                        row.stage_status
                      )}`} // Apply dynamic background color
                      onClick={() => handleStartStage(row._id)} // Pass _id to handleStartStage function
                    >
                      <td className="text-center ">{row._id}</td>
                      <td className="text-center">{row.stage_name}</td>
                      <td className="text-center">{row.stage_status}</td>
                      <td className="text-center">{row.stage_estimate_time}</td>
                      <td className="text-center text-[10px]">
                        {row.stage_supervisor_note}
                      </td>
                      <td className="text-center text-[10px]">
                        {formatDateTime(row.stage_start_at)}
                      </td>{" "}
                      {/* Format start time */}
                      <td className="text-center text-[10px]">
                        {formatDateTime(row.stage_end_at)}
                      </td>{" "}
                      {/* Format end time */}
                      <td className="text-center">
                        {hasPermission(1411) && (
                          <button className="bg-[#99BC85] hover:bg-[#86b46c] text-white font-bold p-2 px-2s rounded-full">
                            <MdOutlineNotStarted size={20} />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-center mt-1">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className={`px-3 py-1 rounded-md mr-2 ${
                  currentPage === 1
                    ? "bg-gray-200 cursor-not-allowed"
                    : "bg-gray-300 cursor-pointer"
                }`}
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
              {[...Array(totalPages)].map((_, page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page + 1)}
                  className={`px-3 py-1 rounded-md mr-2 ${
                    page + 1 === currentPage ? "bg-gray-300" : "bg-gray-200"
                  }`}
                >
                  {page + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className={`px-3 py-1 rounded-md mr-2 ${
                  currentPage === totalPages
                    ? "bg-gray-200 cursor-not-allowed"
                    : "bg-gray-300 cursor-pointer"
                }`}
                disabled={currentPage === totalPages}
              >
                {">"}
              </button>

              <button
                onClick={togglePopup}
                className="cursor-pointer font-bold opacity-45 ml-[150px] mt-2"
              >
                + Add New Stage
              </button>
            </div>
            {/* End Pagination */}
          </>
        )}

        {/* Popup */}
        {showPopup && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-md">
              <h2 className="text-xl font-bold mb-4">Add New Stage</h2>
              <div className="mb-4">
                <label className="block mb-2">Stage Name:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-md px-3 py-1 w-full"
                  value={stageName}
                  onChange={(e) => setStageName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Supervisor Note:</label>
                <textarea
                  className="border border-gray-300 rounded-md px-3 py-1 w-full"
                  value={supervisorNote}
                  onChange={(e) => setSupervisorNote(e.target.value)}
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Estimate Time:</label>
                <div className="flex">
                  <input
                    type="number"
                    min="0"
                    max="23"
                    placeholder="HH"
                    className="border border-gray-300 rounded-md px-3 py-1 w-1/2 mr-2"
                    value={hours}
                    onChange={(e) => setHours(e.target.value)}
                  />
                  <span className="flex items-center">:</span>
                  <input
                    type="number"
                    min="0"
                    max="59"
                    placeholder="MM"
                    className="border border-gray-300 rounded-md px-3 py-1 w-1/2 ml-2"
                    value={minutes}
                    onChange={(e) => setMinutes(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-4">
                <label className="block mb-2">Supervisor:</label>
                <select
                  className="border border-gray-300 rounded-md px-3 py-1 w-full"
                  value={supervisor}
                  onChange={(e) => setSupervisor(e.target.value)}
                >
                  <option value="">Select Supervisor</option>
                  {/* Add options for supervisors */}
                </select>
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => {
                    // Add logic to handle form submission
                    console.log("Submitted!");
                    // Reset input fields and close popup
                    setStageName("");
                    setSupervisorNote("");
                    setEstimateTime("");
                    setSupervisor("");
                    setShowPopup(false);
                  }}
                  className="bg-[#99BC85] text-white px-4 py-2 rounded-md mr-2"
                >
                  Add Stage
                </button>
                <button
                  onClick={togglePopup}
                  className="bg-gray-300 px-4 py-2 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {/* End Popup */}
      </div>
    </div>
  );
}

export default StageData;
