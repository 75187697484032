import React, { useEffect, useState } from "react";
import "./createItem.css";
import axios from "axios";

import IdGenerate from "../../../utils/id_generate";

export default function CreateItem() {
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");

  const [data, setData] = useState({
    item_id: IdGenerate("ITEM"),
    item_name: "",
    item_category_id: "",
    item_category_name: "",
    item_measure_unit: "",
    item_unit_selling_price: "",
    item_description: "",
    item_update_date: formattedDate,
    item_update_user_id: userId,
    item_status: "ACTIVE",
  });

  const [category, setCategory] = useState([]);

  const GetItemCategory = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/itemCategory/status/ACTIVE`
      );
      // console.log(res.data);
      setCategory(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    GetItemCategory();
  }, []);

  const SubmitHandler = async () => {
    console.log(data);
    if (
      data.item_name !== "" &&
      data.item_category_id !== "" &&
      data.item_measure_unit !== ""
    ) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/item/add`,
          data
        );
        // console.log(res.data);
        if (res.status === 200 || res.status === 201) {
          alert("Item Added Successfully");
          ResetHandler();
        }
      } catch (error) {
        if (error.response.status === 409) {
          alert("Item Already Exist");
        } else if (error.response.status === 400) {
          alert("Item Not Added");
        } else if (error.response.status === 500) {
          alert("Internal Server Error");
        } else if (error.response.status === 404) {
          alert("Item Not Found");
        } else if (error.response.status === 403) {
          alert("Forbidden");
        } else if (error.response.status === 401) {
          alert("Unauthorized");
        }
      }
    } else {
      alert("Please Fill All Fields");
    }
  };

  const ResetHandler = () => {
    setData({
      item_id: IdGenerate("ITEM"),
      item_name: "",
      item_category_id: "",
      item_category_name: "",
      item_measure_unit: "",
      item_unit_selling_price: "",
      item_description: "",
      item_update_date: formattedDate,
      item_update_user_id: userId,
      item_status: "ACTIVE",
    });
  };
  return (
    <div className="p-10 bg-background h-screen overflow-y-auto">
      <div className="mb-20">
        <p className="header-1">Create Item</p>
      </div>
      <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] py-11">
        <div className="text-center my-5 mb-20">
          <p className="header-1">Enter Item Details</p>
        </div>
        <div className="flex justify-between">
          <div className="CreateItem-info-div">
            <div className="CreateItem-form ml-5">
              <label className="label-1">Item Id</label>
              <label className="label-1">:</label>
              <input
                type="text"
                className="input-1 border border-[#00000036]"
                value={data.item_id}
                onChange={() => {}}
                disabled
              />
            </div>
            <div className="CreateItem-form ml-5">
              <label className="label-1">Item Name</label>
              <label className="label-1">:</label>
              <input
                type="text"
                className="input-1 border border-[#00000036]"
                value={data.item_name}
                onChange={(e) => {
                  const Data = { ...data };
                  Data.item_name = e.target.value;
                  setData(Data);
                }}
              />
            </div>
            <div className="CreateItem-form ml-5">
              <label className="label-1">Item Category</label>
              <label className="label-1">:</label>
              <select
                className="input-1 border border-[#00000036]"
                value={data.item_category_name}
                onChange={(e) => {
                  const Data = { ...data };
                  Data.item_category_name = e.target.value.split("/")[0];
                  Data.item_category_id = e.target.value.split("/")[1];
                  setData(Data);
                }}
              >
                <option value="">
                  {data.item_category_name === ""
                    ? "select category"
                    : data.item_category_name}
                </option>
                {category.length > 0
                  ? category.map((category, index) => {
                      return (
                        <option
                          key={index}
                          value={`${category.item_category_name}/${category.item_category_id}`}
                        >
                          {category.item_category_name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
          </div>
          <div className="CreateItem-info-div mr-5">
            <div className="CreateItem-form ml-5">
              <label className="label-1">Item Measure Unit</label>
              <label className="label-1">:</label>
              <select
                className="input-1 border border-[#00000036]"
                value={data.item_measure_unit}
                onChange={(e) => {
                  const Data = { ...data };
                  Data.item_measure_unit = e.target.value;
                  setData(Data);
                }}
              >
                <option value="">select measure unit</option>
                <option value="kg">kg</option>
                <option value="l">l</option>
                <option value="pcs">pcs</option>
              </select>
            </div>
            <div className="CreateItem-form ml-5">
              <label className="label-1">Item Unit Selling Price</label>
              <label className="label-1">:</label>
              <input
                type="text"
                className="input-1 border border-[#00000036]"
                value={data.item_unit_selling_price}
                onChange={(e) => {
                  const Data = { ...data };
                  Data.item_unit_selling_price = e.target.value;
                  setData(Data);
                }}
              />
            </div>
            <div className="CreateItem-form ml-5">
              <label className="label-1">Item Description</label>
              <label className="label-1">:</label>
              <input
                type="text"
                className="input-1 border border-[#00000036]"
                value={data.item_description}
                onChange={(e) => {
                  const Data = { ...data };
                  Data.item_description = e.target.value;
                  setData(Data);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 mb-30 mt-10 px-[100px]">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
          onClick={SubmitHandler}
        >
          <p className="text-white">ADD</p>
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
          onClick={ResetHandler}
        >
          <p className="text-white">CANCEL</p>
        </button>
      </div>
    </div>
  );
}
