 

import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Alert from "../../../components/alert";
import usePermissions from "../../../components/permissions/permission";

export default function UpdateCustomer() {
  const { hasPermission } = usePermissions();
  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [nic, setNic] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [customerType, setCustomertype] = useState("");
  const [regNo, setRegNo] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [creditLimit, setCreditLimit] = useState("");
  const [availableCreditLimit, setAvailableCreditLimit] = useState("");

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");

  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      borderColor: state.isFocused ? "grey" : "red",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  //discount
  const [discount, setDiscount] = useState([
    {
      discount_from: 0,
      discount_to: 0,
      rate: 0,
    },
  ]);

  const [customers, setCustomers] = useState([]);
  const CustomerSearchHandler = async (inputValue) => {
    // console.log(inputValue)
    // setCustomerName(inputValue);
    if (inputValue !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/customer/search/name/contact/status/${inputValue}/ACTIVE`
        );
        // console.log(res.data)
        const options = res.data.map((customer) => ({
          value: customer.customer_id,
          label: customer.customer_name,
        }));
        setCustomers(options);
        return options;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
  };
  const CustomerSelectHandler = async (customer) => {
    GetCustomer(customer.value);
  };
  const GetCustomer = async (customerId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/customer/get/id/${customerId}`
      );

      setId(res.data[0].customer_id);
      setName(res.data[0].customer_name);
      setNic(res.data[0].customer_nic);
      setAddress(res.data[0].customer_address);
      setContact(res.data[0].customer_contact_no);
      setEmail(res.data[0].customer_email);
      setCustomertype(res.data[0].customer_type);
      setRegNo(res.data[0].reg_no);
      setPaymentMethod(res.data[0].customer_payment_method);
      setCreditLimit(res.data[0].credit_limit);
      setAvailableCreditLimit(res.data[0].available_credit_limit);
      GetDiscount(res.data[0].customer_id);
    } catch (error) {}
  };
  const GetDiscount = async (customerId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/customer/get/discount/id/status/${customerId}/ACTIVE`
      );
      // console.log(res.data)
      setDiscount(res.data);
    } catch (error) {}
  };

  const UpdateHandler = async () => {
    // console.log('id',id)
    // console.log('name', name)
    // console.log('nic', nic)
    // console.log('address', address)
    // console.log('contact', contact)
    // console.log('email', email)
    // console.log('customerType', customerType)
    // console.log('regNo', regNo)
    // console.log('paymentMethod', paymentMethod)
    // console.log('creditLimit', creditLimit)
    // console.log('discount', discount)

    if (name === "") {
      window.alert("fill the name");
    } else if (nic === "") {
      window.alert("fill the nic");
    } else if (contact === "") {
      window.alert("fill the contact");
    } else if (email === "") {
      window.alert("fill the email");
    } else if (customerType === "") {
      window.alert("fill the customer type");
    } else if (regNo === "" && customerType === "doctor") {
      window.alert("fill the reg no");
    } else if (paymentMethod === "") {
      window.alert("fill the payment method");
    } else {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/customer/update`,
          {
            customer_id: id,
            customer_name: name,
            customer_nic: nic,
            customer_address: address,
            customer_contact_no: contact,
            customer_email: email,
            customer_type: customerType,
            reg_no: regNo,
            customer_payment_method: paymentMethod,
            credit_limit: creditLimit,
            available_credit_limit: availableCreditLimit,
            status: "ACTIVE",
            user_id: userId,
            update_date: formattedDate,
            discount: discount,
          }
        );
        // console.log(res.data)
        setAlert({
          status: "success",
          alert: "Customer Updated Successfully",
          show: true,
        });
      } catch (error) {
        console.error(error);
        setAlert({
          status: "error",
          alert: "Customer Updated Failed",
          show: true,
        });
      }
    }
  };

  const CancelHandler = () => {
    setId("");
    setName("");
    setNic("");
    setAddress("");
    setContact("");
    setEmail("");
    setCustomertype("");
    setRegNo("");
    setPaymentMethod("");
    setCreditLimit("");
    setAvailableCreditLimit("");
    GetDiscount("");
    setDiscount([
      {
        discount_from: 0,
        discount_to: 0,
        rate: 0,
      },
    ]);
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center px-20 bg-background ">
      <Alert
        show={alert.show}
        alert={alert.alert}
        status={alert.status}
        ok={() => {
          setAlert({
            status: "",
            alert: "",
            show: false,
          });
        }}
      />
      <div className="w-full flex justify-start">
        <p className="header-1 mt-5">View & Update Customer</p>
      </div>

      <div className="w-full flex justify-start mt-10 gap-10">
        <p className="label-1">Find Customer </p>
        <p className="label-1">: </p>
        <AsyncSelect
          className="w-80"
          styles={customStyles}
          cacheOptions
          loadOptions={CustomerSearchHandler}
          // defaultOptions
          onChange={CustomerSelectHandler}
          value={customers}
        />
      </div>

      <div className="w-full my-10 px-10 bg-[#F3F8FF] rounded-[46px] border border-[#00000036]">
        <p className="header-1 w-full text-center">Enter Customer Details</p>
        <div className="w-full flex justify-between flex-wrap gap-y-10 my-12">
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Customer ID :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
              value={id}
              onChange={(e) => setId(e.target.value)}
              disabled
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Customer Name :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">NIC :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
              value={nic}
              onChange={(e) => setNic(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Address :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Contact number :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">E-mail Address :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Customer type :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
              value={customerType}
              onChange={(e) => setCustomertype(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Reg. No. :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
              value={regNo}
              onChange={(e) => setRegNo(e.target.value)}
            />
          </div>
        </div>

        <div className="flex items-center justify-between w-full py-10">
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Payment Method :</p>
            <select
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option>select</option>
              <option value={"cash"}>cash</option>
              <option value={"credit"}>credit</option>
            </select>
          </div>
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Credit Limit :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              value={creditLimit}
              onChange={(e) => setCreditLimit(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="w-full bg-[#F3F8FF] px-20 py-5 rounded-[46px] flex flex-col items-center justify-center mx-16 mt-5">
        <p className="header-1 mb-10">Enter Discount Details</p>

        {discount.length > 0
          ? discount.map((data, index) => {
              return (
                <div
                  key={index}
                  className="w-full flex justify-between items-center my-2"
                >
                  <div className="flex flex-col gap-y-3">
                    <p className="label-1">From :</p>
                    <input
                      className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
                      value={data.discount_from}
                      type="number"
                      onChange={(e) => {
                        const Data = [...discount];
                        Data[index].discount_from = e.target.value;
                        setDiscount(Data);
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-y-3">
                    <p className="label-1">To :</p>
                    <input
                      className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
                      value={data.discount_to}
                      type="number"
                      onChange={(e) => {
                        const Data = [...discount];
                        Data[index].discount_to = e.target.value;
                        setDiscount(Data);
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-y-3">
                    <p className="label-1">Discount Rate : </p>
                    <input
                      className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
                      value={data.rate}
                      type="number"
                      onChange={(e) => {
                        const Data = [...discount];
                        Data[index].rate = e.target.value;
                        setDiscount(Data);
                      }}
                    />
                  </div>
                  <div className="pt-12">
                    <button
                      className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
                      onClick={() => {
                        const Data = [...discount];
                        Data.splice(index, 1);
                        setDiscount(Data);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              );
            })
          : null}

        <div className="w-full flex justify-start my-9">
          {hasPermission(1550) && (
            <button
              className="w-[118px] h-[45px] flex items-center justify-center bg-[#43aa43] border rounded-[23px] px-4 text-white font-bold text-xl"
              onClick={() => {
                const Data = [...discount];
                Data.push({
                  discount_from: Data.length > 0 ? Data[Data.length - 1].to : 0,
                  discount_to: 0,
                  rate: 0,
                });
                setDiscount(Data);
              }}
            >
              add
            </button>
          )}
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={CancelHandler}
        >
          Cancel
        </button>
        {hasPermission(1560) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl"
            onClick={UpdateHandler}
          >
            Update
          </button>
        )}
      </div>
    </div>
  );
}
