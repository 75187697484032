/** @format */

import {
  FiUser,
  FiUsers,
  FiArchive,
  FiClipboard,
  FiFileText,
  FiCheckSquare,
  FiMapPin,
  FiChevronDown,
  FiTruck,
} from "react-icons/fi";
import { HiUsers } from "react-icons/hi";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { FaCashRegister } from "react-icons/fa";
import { FaWarehouse, FaPersonChalkboard } from "react-icons/fa6";
import { IoSettingsSharp } from "react-icons/io5";
import { FcApproval } from "react-icons/fc";

const systemsList = [
  {
    _id: "1",
    name: "POS",
    url: "",
    icon: <FaCashRegister />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["1"],
    features: [
      {
        _id: "1.1",
        name: "POS",
        url: "/pos/pos",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["100"],
      },
      {
        _id: "1.2",
        name: "POS BILLS",
        url: "/pos/bill",
        requiredPermissionsforsubfeatures: ["101"],
      },
      {
        _id: "1.3",
        name: "DASHBOARD",
        url: "/pos/pos-dashboard",
        requiredPermissionsforsubfeatures: ["102"],
      },

      {
        _id: "1.5",
        name: "ITEMS",
        url: "/pos/pos-items",
        requiredPermissionsforsubfeatures: ["103"],
      },
      {
        _id: "1.6",
        name: "MOBILE POS",
        url: "/pos/mobile-pos",
        requiredPermissionsforsubfeatures: ["104"],
      },
    ],
  },
  {
    _id: "2",
    name: "INVENTORY",
    url: "",
    icon: <FiArchive />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["2"],
    features: [
      {
        _id: "2.1",
        name: "INVENTORY PURCHASE",
        url: "/inventory/purchase",
        requiredPermissionsforsubfeatures: ["110"],
      },
      {
        _id: "2.2",
        name: "INVENTORY STORE",
        url: "/inventory/store",
        requiredPermissionsforsubfeatures: ["111"],
      },
      {
        _id: "2.3",
        name: "INVENTORY REQUEST",
        url: "/inventory/request-nav",
        requiredPermissionsforsubfeatures: ["112"],
      },
      {
        _id: "2.4",
        name: "INVENTORY RELEASE",
        url: "/inventory/store-keeper-release",
        requiredPermissionsforsubfeatures: ["113"],
      },
      {
        _id: "2.5",
        name: "INVENTORY DISPOSE",
        url: "/inventory/dispose",
        requiredPermissionsforsubfeatures: ["114"],
      },
      {
        _id: "2.6",
        name: "DISPOSED ITEMS",
        url: "/inventory/disposed",
        requiredPermissionsforsubfeatures: ["115"],
      },
      {
        _id: "2.7",
        name: "RETURN",
        url: "/inventory/return",
        requiredPermissionsforsubfeatures: ["116"],
      },
      {
        _id: "2.8",
        name: "ADJUST",
        url: "/inventory/adjust",
        requiredPermissionsforsubfeatures: ["117"],
      },
      {
        _id: "2.9",
        name: "DASHBOARD",
        url: "/inventory/dashboard",
        requiredPermissionsforsubfeatures: ["118"],
      },
      {
        _id: "2.10",
        name: "ADD ITEM MANUALLY",
        url: "/inventory/add-item-manualy",
        requiredPermissionsforsubfeatures: ["119"],
      },
    ],
  },
  {
    _id: "3",
    name: "PRODUCTION",
    url: "",
    icon: <FaWarehouse />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["3"],
    features: [
      {
        _id: "3.1",
        url: "/recipe-nav",
        name: "Recipe",
        requiredPermissionsforsubfeatures: ["120"],
      },
      {
        _id: "3.2",
        url: "/job-nav",
        name: "JOB",
        requiredPermissionsforsubfeatures: ["121"],
      },
      {
        _id: "3.3",
        name: "UNIT",
        url: "/make-units",
        requiredPermissionsforsubfeatures: ["122"],
      },
      {
        _id: "3.4",
        name: "MANAGE PRODUCTION",
        url: "/manage-production",
        requiredPermissionsforsubfeatures: ["123"],
      },
      {
        _id: "3.5",
        name: "PRODUCTION",
        url: "/start-production",
        requiredPermissionsforsubfeatures: ["124"],
      },
      {
        _id: "3.6",
        name: "WAREHOUSE",
        url: "/warehouse-nav",
        requiredPermissionsforsubfeatures: ["125"],
      },
    ],
  },
  {
    _id: "4",
    name: "HRIS",
    url: "",
    icon: <HiUsers />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["4"],
    features: [
      {
        _id: "4.8",
        name: "Dashboard",
        url: "/dashboard",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["137"],
      },
      {
        _id: "4.1",
        name: "Employee",
        url: "/emp-nav",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["130"],
      },
      {
        _id: "4.2",
        name: "Attendance",
        url: "/attendance",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["131"],
      },
      {
        _id: "4.3",
        name: "Payroll",
        url: "/payments-menu",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["132"],
      },
      {
        _id: "4.4",
        name: "Performance",
        url: "/performance-nav",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["133"],
      },
      // {
      //   _id: "4.5",
      //   name: "Reports & Analytics",
      //   url: "/reports-analytics",
      //   icon: <FiFileText />,
      //   requiredPermissionsforsubfeatures: ["134"],
      // },
      {
        _id: "4.6",
        name: "Leave Management",
        url: "/leaves",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["135"],
      },
      {
        _id: "4.7",
        name: "Loan Information",
        url: "/view-loan-info",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["136"],
      },
    ],
  },
  {
    _id: "5",
    name: "BRANCH",
    url: "",
    icon: <FiMapPin />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["5"],
    features: [
      {
        _id: "5.1",
        name: "ADD BRANCH",
        url: "/branches/add",
        requiredPermissionsforsubfeatures: ["140"],
      },
      {
        _id: "5.2",
        name: "UPDATE BRANCH",
        url: "/branches/update",
        requiredPermissionsforsubfeatures: ["141"],
      },
    ],
  },

  {
    _id: "6",
    name: "CUSTOMER",
    url: "",
    icon: <FiUsers />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["6"],
    features: [
      {
        _id: "6.1",
        name: "ADD CUSTOMER",
        url: "/customer/add",
        requiredPermissionsforsubfeatures: ["150"],
      },
      {
        _id: "6.2",
        name: "UPDATE/VIEW CUSTOMER",
        url: "/customer/update",
        requiredPermissionsforsubfeatures: ["151"],
      },
      {
        _id: "6.3",
        name: "CREDIT CLEARANCE",
        url: "/customer/credit",
        requiredPermissionsforsubfeatures: ["152"],
      },
    ],
  },
  {
    _id: "7",
    name: "INVENTORY TRANSFER",
    url: "",
    icon: <FiClipboard />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["7"],
    features: [
      {
        _id: "7.1",
        name: "REQUEST ITEMS",
        url: "/transfer/request",
        requiredPermissionsforsubfeatures: ["160"],
      },
      {
        _id: "7.2",
        name: "TRANSFER REQUEST",
        url: "/transfer/send",
        requiredPermissionsforsubfeatures: ["161"],
      },
    ],
  },
  {
    _id: "8",
    name: "SUPPLIER",
    url: "",
    icon: <FiClipboard />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["8"],
    features: [
      {
        _id: "8.1",
        name: "ADD SUPPLIER",
        url: "/supplier/add",
        requiredPermissionsforsubfeatures: ["170"],
      },
      {
        _id: "8.2",
        name: "UPDATE SUPPLIER",
        url: "/supplier/update",
        requiredPermissionsforsubfeatures: ["171"],
      },
    ],
  },
  {
    _id: "9",
    name: "ITEM CATEGORY",
    url: "",
    icon: <FiCheckSquare />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["9"],
    features: [
      {
        _id: "9.1",
        name: "ADD ITEM CATEGORY",
        url: "/itemcategory/add",
        requiredPermissionsforsubfeatures: ["180"],
      },
      {
        _id: "9.2",
        name: "VIEW & UPDATE ITEM CATEGORY",
        url: "/itemcategory/update",
        requiredPermissionsforsubfeatures: ["181"],
      },
    ],
  },
  {
    _id: "10",
    name: "ITEM",
    url: "",
    icon: <FiCheckSquare />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["10"],
    features: [
      {
        _id: "10.1",
        name: "ADD ITEM",
        url: "/items/create",
        requiredPermissionsforsubfeatures: ["190"],
      },
      {
        _id: "10.2",
        name: "UPDATE ITEM",
        url: "/items/update",
        requiredPermissionsforsubfeatures: ["191"],
      },
    ],
  },
  {
    _id: "11",
    name: "STORES",
    url: "",
    icon: <FiCheckSquare />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["11"],
    features: [
      {
        _id: "11.1",
        name: "ADD STORE",
        url: "/stores/add",
        requiredPermissionsforsubfeatures: ["200"],
      },
      {
        _id: "11.2",
        name: "UPDATE STORE",
        url: "/stores/update",
        requiredPermissionsforsubfeatures: ["201"],
      },
    ],
  },
  {
    _id: "12",
    name: "REPORTS",
    url: "",
    icon: <FiCheckSquare />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["12"],
    features: [
      {
        _id: "12.1",
        name: "ITEMS",
        url: "/reports/item",
        requiredPermissionsforsubfeatures: ["210"],
      },
      {
        _id: "12.2",
        name: "CATEGORY",
        url: "/reports/category",
        requiredPermissionsforsubfeatures: ["211"],
      },
    ],
  },
  {
    _id: "13",
    name: "DESIGNATION",
    url: "",
    icon: <BsFillPersonVcardFill />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["13"],
    features: [
      {
        _id: "13.1",
        name: "ADD DESIGNATION",
        url: "/designation/add",
        requiredPermissionsforsubfeatures: ["220"],
      },
      {
        _id: "13.2",
        name: "VIEW & UPDATE DESIGNATION",
        url: "/designation/update",
        requiredPermissionsforsubfeatures: ["221"],
      },
    ],
  },
  {
    _id: "14",
    name: "TRAINING TYPE",
    url: "",
    icon: <FaPersonChalkboard />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["14"],
    features: [
      {
        _id: "14.1",
        name: "ADD TRAINING TYPE",
        url: "/trainingtype/add",
        requiredPermissionsforsubfeatures: ["230"],
      },
      {
        _id: "14.2",
        name: "VIEW & UPDATE TRAINING TYPE DESCRIPTION",
        url: "/trainingtype/update",
        requiredPermissionsforsubfeatures: ["231"],
      },
    ],
  },
  {
    _id: "15",
    name: "SUPPLY CHAIN",
    url: "",
    icon: <FiTruck />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["15"],
    features: [
      {
        _id: "15.1",
        name: "PURCHASE REQUEST",
        url: "/purchase/request",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["240"],
      },
      {
        _id: "15.2",
        name: "DECISIONS",
        url: "/supply/decisions",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["241"],
      },
      {
        _id: "15.3",
        name: "SUPPLIERS",
        url: "/suppliers/supply",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["242"],
      },
      {
        _id: "15.4",
        name: "COMMITTEES",
        url: "/committees/home",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["243"],
      },
      {
        _id: "15.5",
        name: "PURCHASE ORDER",
        url: "/purchaseOrder/home",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["244"],
      },
      {
        _id: "15.6",
        name: "Dashboard",
        url: "/supply/dashboard",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["245"],
      },
    ],
  },
  {
    _id: "16",
    name: "SETTINGS",
    icon: <IoSettingsSharp />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["16"],
    features: [
      {
        _id: "16.1",
        name: "USERS PERMISSION",
        url: "/settings_user",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["250"],
      },

      {
        _id: "16.2",
        name: "USER MANAGEMENT",
        url: "/user_management",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["251"],
      },
    ],
  },

  //approval
  {
    _id: "17",
    name: "Approval Process",
    icon: <FcApproval />,
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["17"],
    features: [
      {
        _id: "17.1",
        name: "Approval One",
        url: "/approve-1-modules",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["260"],
      },

      {
        _id: "17.2",
        name: "Approval Two",
        url: "/approve-2-modules",
        icon: <FiFileText />,
        requiredPermissionsforsubfeatures: ["261"],
      },
    ],
  },
  //Branch Req
  {
    _id: "18",
    name: "Branch Request",
    icon: <FiArchive />,
    url: "/branch-req-nav",
    expandIcon: <FiChevronDown />,
    requiredPermissionsformainfeatures: ["270"],
  },
];

export default systemsList;
