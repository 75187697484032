 

import React, { useEffect, useState, useRef } from "react";
import "./billPreview1.css";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import BillLogo from "../../../src/Assets/icons/bill logo.svg";
import usePermissions from "../../components/permissions/permission";

export default function BillPreview1(props) {
  const { hasPermission } = usePermissions();
  const [billPreviewItems, setBillPreviewItems] = useState([]);
  const [billId, setBillid] = useState("");
  const [branchName, setBranchName] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [billDate, setBillDate] = useState("");
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [cash, setCash] = useState(0);
  const [change, setChange] = useState(0);

  useEffect(() => {
    GetBill(props.id);
  }, [props.id]);

  const GetBill = async (id) => {
    if (id !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/pos/getPos/${id}`
        );
        console.log(res.data);
        setBillPreviewItems(res.data);
        setBillid(res.data[0].pos_id);
        setCustomerName(res.data[0].customer_name);
        setBillDate(res.data[0].pos_date);
        setUserId(res.data[0].pos_user_id);
        setTotal(res.data[0].pos_sub_total.toFixed(2));
        setDiscount(res.data[0].pos_discount.toFixed(2));
        setNetTotal(res.data[0].pos_net_total.toFixed(2));
        setCash(res.data[0].pos_cash.toFixed(2));
        setChange(res.data[0].pos_change.toFixed(2));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  const GetUser = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/get/${id}`
      );
      console.log(res.data);
      setUserName(res.data[0].user_name);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userId !== "") {
      GetUser(userId);
    }
  }, [userId]);

  return (
    <div className="top-0 h-full w-full bg-[#E5EDF9] px-10 py-10">
      <p className="header-1">Pos Bill</p>
      <div className="w-full flex items-center justify-between">
        <div className="w-[450px] flex items-center justify-between gap-x-10">
          <p className="label-1 w-[110px]">Bill Id</p>
          <p className="label-1">:</p>
          <p className="w-full h-[40px] bg-[#FEFFFF] rounded-[46px] flex items-center font-bold justify-start my-10 px-5">
            {billId}
          </p>
        </div>
        <div className="w-[520px] flex items-center justify-between gap-x-10">
          <label className="label-1 w-[500px]">Customer Name</label>
          <label className="label-1 w-[50px]">:</label>
          <label className="w-full h-[40px] bg-[#FEFFFF] rounded-[46px] font-bold flex items-center justify-start my-10 px-5">
            {customerName}
          </label>
        </div>
        <div className="w-[450px] flex items-center justify-between gap-x-10">
          <label className="label-1 w-[250px]">Bill Date</label>
          <label className="label-1 w-[50px]">:</label>
          <label className="w-full h-[40px] bg-[#FEFFFF] rounded-[46px] flex items-center font-bold justify-start my-10 px-5">
            {billDate}
          </label>
        </div>
      </div>

      <div className="flex flex-col justify-start bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
        <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
          <p className="w-10 p1 text-[#656565] text-center">#</p>
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center">Item Name</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center">Qty</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center">unit</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center">unit price</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center">Total</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
          {billPreviewItems.length > 0 ? (
            billPreviewItems.map((item, index) => {
              return (
                <div key={index} className="w-full flex justify-around">
                  <p className="w-10 p1 text-[#656565] text-center">
                    {index + 1}
                  </p>
                  <div className="w-[350px] flex items-center">
                    <p className="w-full p1 text-[#656565] text-center">
                      {item.item_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex items-center">
                    <p className="w-full p1 text-[#656565] text-center">
                      {item.pos_item_qty}
                    </p>
                  </div>
                  <div className="w-[200px] flex items-center">
                    <p className="w-full p1 text-[#656565] text-center">
                      {item.item_measure_unit}
                    </p>
                  </div>
                  <div className="w-[200px] flex items-center">
                    <p className="w-full p1 text-[#656565] text-center">
                      {item.pos_items_price}
                    </p>
                  </div>
                  <div className="w-[200px] flex items-center">
                    <p className="w-full p1 text-[#656565] text-center">
                      {item.pos_item_qty * item.pos_items_price}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </div>
      </div>

      <div className="w-full flex justify-end mt-[20px]">
        <div className="w-[400px] flex flex-col gap-y-3 p-[20px] bg-[#F3F8FF] px-10 rounded-[46px] border border-[#00000036]">
          <div className="flex items-center justify-between px-5">
            <p className="p1 text-[#656565] text-center">Sub Total</p>
            <p className="p1 text-[#656565] text-center">{total}</p>
          </div>
          <div className="flex items-center justify-between px-5">
            <p className="p1 text-[#656565] text-center">Discount</p>
            <p className="p1 text-[#656565] text-center">{discount}</p>
          </div>
          <div className="flex items-center justify-between px-5">
            <p className="p1 text-[#656565] text-center">Net Total</p>
            <p className="p1 text-[#656565] text-center">{netTotal}</p>
          </div>
          <div className="flex items-center justify-between px-5">
            <p className="p1 text-[#656565] text-center">Cash</p>
            <p className="p1 text-[#656565] text-center">{cash}</p>
          </div>
          <div className="flex items-center justify-between px-5">
            <p className="p1 text-[#656565] text-center">Change</p>
            <p className="p1 text-[#656565] text-center">{change}</p>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-end items-center gap-x-10 my-10">
        {hasPermission(1080) && (
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
            onClick={handlePrint}
          >
            <p className="p2 text-white">Print</p>
          </button>
        )}
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#912020] border rounded-[23px] px-4"
          onClick={props.CancelHandler}
        >
          <p className="p2 text-white">Cancel</p>
        </button>
      </div>

      <div style={{ display: "none" }}>
        <div ref={contentToPrint}>
          <div className="bill-container">
            <div className="header">
              <img src={BillLogo} alt="Bill Logo" className="bill-logo" />
              <h1>Sri Lanka Ayurvedic Drugs Corporation</h1>
              <p>No.94 Old Kottawa Road Maharagama Sri Lanka</p>
              <p>Phone: 0112850229 Email: itsladc@gmail.com</p>
              <p>Website: www.sladc.lk</p>
            </div>
            <hr />
            <div className="invoice-details">
              <p>Invoice No: {billId}</p>
              <p>Date: {billDate}</p>
              <p>Cashier: {userName}</p>
            </div>
            <hr />
            <div className="customer-details">
              <p>Customer Name: {customerName}</p>
              <p>Contact: {customerName}</p>
              <p>Billing Address: {customerName}</p>
            </div>
            <hr />
            <table className="product-table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {billPreviewItems.length > 0 &&
                  billPreviewItems.map((product, index) => (
                    <tr key={index}>
                      <td>{product.item_name}</td>
                      <td>{product.pos_item_qty}</td>
                      <td>{product.pos_items_price}</td>
                      <td>{product.pos_items_price * product.pos_item_qty}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <hr />
            <div className="totals">
              <p>Subtotal: {total}</p>
              <p>Discount: {discount}</p>
              <hr />
              <p>Grand Total: {netTotal}</p>
            </div>
            <hr />
            <div className="footer">
              <p>Please check the products prior to purchase.</p>
              <p>Payments are non-refundable under any circumstance.</p>
              <p>
                We are not responsible for any damages or discrepancies reported
                after products have been dispatched.
              </p>
              <p>
                We do not accept returns or exchanges after the purchase has
                been completed.
              </p>
              <p>For any inquiries Contact us at: 0112850229</p>
              <h2>Thank you!</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
