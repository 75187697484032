/** @format */

import React, { useState, useEffect } from "react";
import HRIS_Permission from "./user_management/hris_permission";
import Production_Permission from "./user_management/production_permission";
import Inventory from ".//user_management/inventory_permssion";
import POS from "./user_management/pos_permission";
import Branch from "./user_management/branch_permission";
import Customer from "./user_management/customer_permission";
import Supplier from "./user_management/supplier_permission";
import ItemCategory from "./user_management/itemCategory_permission";
import Item from "./user_management/item_permission";
import Store from "./user_management/store_permission";
import Designation from "./user_management/designation_permission";
import TrainingType from "./user_management/Traniningtype_permission";
import SupplyChain from "./user_management/supplychain_permission";
import Settings from "./user_management/settings_permissions";
import Approvals from "./user_management/approval_permission";
import BranchReq from "./user_management/BranchReq_permissions";
import { useNavigate, useLocation } from "react-router-dom";

const Permission = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // Destructure with default values
  const { selectedRecord = {}, updatestatus = "" } = location.state || {};
  const [roleName, setRoleName] = useState(selectedRecord.role_name || "");
  const [roleDescription, setRoleDescription] = useState(
    selectedRecord.role_description || ""
  );
  const [tableData, setTableData] = useState(selectedRecord || []);
  const [permissions, setPermissions] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handlePermissionsChange = (selectedPermissions) => {
    setPermissions((prevPermissions) => [
      ...new Set([...prevPermissions, ...selectedPermissions]),
    ]);
  };

  const [roleData, setRoleData] = useState({
    userPermissions: [],
  });

  useEffect(() => {
    fetchRoleids();
  }, []);

  const fetchRoleids = async () => {
    console.log("updatestatus :", updatestatus);
    if (updatestatus) {
      console.log("permission :", selectedRecord);
      if (selectedRecord) {
        try {
          const response = await fetch(
            `${REACT_APP_API_ENDPOINT}/v1/hris/user/getRolePermissions?role_id=${selectedRecord.id}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          const userPermissions = data.map((item) => item.id);

          // Update the state with the extracted IDs
          setRoleData({
            userPermissions,
          });

          console.log("permission array:", userPermissions);
        } catch (error) {
          console.error("Error fetching user roles:", error);
        }
      }
    }
  };
  const handleSave = async () => {
    if (!roleName || !roleDescription) {
      window.alert("Role Name and Role Description cannot be empty");
      return; // Exit the function if validation fails
    }
    const postData = {
      role_name: roleName,
      role_description: roleDescription,
      permissions,
    };
    //
    console.log("Sent Data:", postData);
    if (postData) {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/user/addUserRole`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log("Response Data:", data);
          window.alert("Role Added Successfully");
          handlecancel();
        } else {
          console.error("Failed to save data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleUpdate = async () => {
    const postData = {
      role_id: selectedRecord.id,
      role_name: roleName,
      role_description: roleDescription,
      permissions,
    };
    //
    console.log("Sent Data1:", postData);

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/user/updateUserRole`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Response Data:", data);
        window.alert("Role Updated Successfully");
        handlecancel();
      } else {
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlecancel = async () => {
    setRoleName("");
    setRoleDescription("");
    setPermissions([]);
    navigate("/settings_user");
  };

  return (
    <div className="font-sans bg-background h-screen overflow-y-auto">
      <div>
        <p className="mt-10 ml-6 text-headerColor text-[28px] font-semibold">
          Permission
        </p>
      </div>

      <div className="shadow-md overflow-y-auto bg-[#F4F7FCBF] rounded-[6px] opacity-75 w-[92%] h-[70%] ml-[20px] mt-[20px] mb-10">
        <div className="ml-[20%] grid grid-cols-2 gap-4">
          <div className="mt-8">
            <p className="font-bold text-[20px]">Role*</p>
            <input
              className="border border-none bg-white rounded-[20px] h-[40px] w-[300px] p-2"
              type="text"
              value={selectedRecord.role_name}
              onChange={(e) => setRoleName(e.target.value)}
            />
          </div>

          <div className="mt-8">
            <p className="font-bold text-[20px]">Role Description</p>
            <input
              className="border border-none bg-white rounded-[20px] h-[40px] w-[300px] p-2"
              type="text"
              value={selectedRecord.role_description}
              onChange={(e) => setRoleDescription(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-10 gap-[40px] grid ">
          <div>
            <HRIS_Permission
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <Production_Permission
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <Inventory
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <POS
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <Branch
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <Customer
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <Supplier
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <ItemCategory
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <Item
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <Store
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <Designation
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <TrainingType
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <SupplyChain
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <Settings
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <Approvals
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
          <div>
            <BranchReq
              onPermissionsChange={handlePermissionsChange}
              userPermissions={roleData.userPermissions}
            />
          </div>
        </div>

        <div className="grid grid-col-3 grid-flow-row gap-4"></div>
      </div>

      <div className="ml-[6%] flex items-center gap-3">
        <button
          className="bg-green-500 text-white p-2 font-bold rounded-[10px]"
          onClick={updatestatus ? handleUpdate : handleSave}
        >
          {updatestatus ? "Update" : "Save"}
        </button>
        <button
          className="bg-red-500 text-white font-bold p-2 rounded-[20px]"
          onClick={handlecancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Permission;
